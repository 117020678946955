import React, { Component } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';


import {
    BrowserRouter as Router,
    Switch, Route, Link,useParams
} from "react-router-dom";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {

    },
    root2: {
        marginTop:10,


    },
    media: {
        height:'150px',
        width:'150px',
        paddingTop: '56.25%', // 16:9
    },
    root3: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: 'white',
        height: 75,
        width: 100,
        backgroundColor:'green'
    },
}));


/*const useStyles = makeStyles({
    root: {

    },
    root2: {
         marginTop:10,


    },
    media: {
        height:'150px',
        width:'150px',
        paddingTop: '56.25%', // 16:9
    },
    root3: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },


});*/

const db = firebase.firestore();
const storage = firebase.storage();

const avatarImageStyle = {
    width: 200,
    height: 200,
};


var  bookingId  =  "";
var  bookingStatus = "";

var beId = [{beId:""}];
var beName = [{beName:""}];


const eventImages = [];
var blockArray = [];




export default function BookingsDetails()  {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    let  {id}  = useParams();
    bookingId = useParams().id;
    bookingStatus = useParams().status;
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);


    const [selectedStatus, setSelectedStatus] = React.useState({
        selectedStatus: useParams().status,
    });



    const handleClick3 = () => {
        setOpen(true);
    };

    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const handleClose = () => {
        setAnchorEl(null);

       // document.getElementById("b-status-button").innerHTML = "Action"



    };

    const handleClose1 = (props) => (event) => {
        setAnchorEl(null);

        setSelectedStatus({...selectedStatus,selectedStatus: props});

       // document.getElementById("b-status-button").innerHTML = "Processing";
        db.collection("booking").doc(bookingId).update({

             "b_e_status":props

        }).then(function () {

            // alert('Successfully Updated status')
             handleClick3();
        })


    };
    const handleClose2 = () => {
        setAnchorEl(null);

        document.getElementById("b-status-button").innerHTML = "Cancelled";
        db.collection("booking").doc(bookingId).update({

            "b_e_status":"cancelled"

        }).then(function () {

            handleClick3();
        })
    };


    db.collection("booking").doc(bookingId).get().then(function (doc) {

        if (doc.exists) {
            console.log("Document data:", doc.data());

            const bookingData = doc.data();
            const beId = bookingData.b_e_id;
            const beName = bookingData.b_e_name;
            const beDescription = bookingData.b_e_description;
            const beStatus = bookingData.b_e_status;
            const hallType = bookingData.b_e_hall_type;
            const isLive = bookingData.is_live;
            const oName = bookingData.o_name;
            const organizationName = bookingData.o_c_name;

          //  beId[0].beId = beId;
          //  beName[0].beName = beName;

           // alert(bookingId);

            document.getElementById("e-name").innerHTML = beName;
           // document.getElementById("e-description").innerHTML = "Event Description : "+beDescription;
            document.getElementById("e-hallType").innerHTML = hallType;
            document.getElementById("o-name").innerHTML = oName;
            document.getElementById("o-c-name").innerHTML = organizationName;

            db.collection("booking").doc(beId).collection("booking_dates").get().then(function (querySnapshot) {

                var count = 0;
                var bookingTime = '';
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    count++;
                    var bookingData = doc.data();
                    var bookingDate = bookingData.booked_date;
                    var fromTime = bookingData.from;
                    var toTime = bookingData.to;

                    bookingTime+= " "+fromTime+" "+toTime;


                    document.getElementById("booking-date").innerHTML = ''+bookingDate;
                    document.getElementById("booking-time").innerHTML = ''+bookingTime;


                });

            });

            db.collection("booking").doc(beId).collection("event_images").get().then(function (querySnapshot) {

                var imageCount = 0;

                var imgView = '';

                querySnapshot.forEach(function(doc) {

                    imageCount++;
                    var imageData = doc.data();
                    var imageUrl = imageData.e_image_url;


                    eventImages.push(imageUrl);
                    //<Avatar style={Object.assign({}, avatarImageStyle, {color: 'grey' })} variant="square" src={row} />

                    imgView+='<img src="'+imageUrl+'" style="height: 150px;width: 150px">';
                    document.getElementById("event-img-gall").innerHTML = ''+imgView;

                });

            });

            db.collection("booking").doc(beId).collection("layout_image").get().then(function (querySnapshot) {

                var limageCount = 0;

                var limgView = '';

                querySnapshot.forEach(function(doc) {

                    limageCount++;
                    var imageData = doc.data();
                    var imageUrl = imageData.l_image_url;


                    limgView+='<img src="'+imageUrl+'" style="height: 150px;width: 150px">';
                    document.getElementById("layout-img-gall").innerHTML = ''+limgView;

                });

            });

            db.collection("booking").doc(beId).collection("event_blocks").get().then(function (querySnapshot) {

                var blockCount = 0;

                var blockView = '';
                blockArray = [];
                querySnapshot.forEach(function(doc) {

                    blockCount++;
                    var blockData = doc.data();
                    var bId = blockData.b_id;
                    var bLabel = blockData.b_lable_name;
                    var bLabelPrice = blockData.b_label_price;


                    blockArray.map(function (data) {




                        if(data.bid ===bId){
                          //  alert(data.bid);
                            blockArray.splice(blockArray.findIndex(v => v.value === bId), 1);


                        }else {

                           // blockArray.splice(blockArray.findIndex(v => v.value === bId), 1);
                           // alert(data.bid);
                        }



                        /* if(data.bid===bId){

                             blockArray.push({bid:bId,bLabel:bLabel,bPrice:bLabelPrice});


                         }else{

                             blockArray.push({bid:bId,bLabel:bLabel,bPrice:bLabelPrice});

                         }*/


                    });



                    blockArray.push({bid:bId,bLabel:bLabel,bPrice:bLabelPrice});


                  //  blockView+='<TableRow><TableCell>'+bLabel+'</TableCell><TableCell>'+bLabelPrice+'</TableCell></TableRow>';
                   // document.getElementById("event-blocks").innerHTML = ''+blockView;
                   // document.getElementById("event-blocks").insert

                });

            });




        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }


    }).catch(function(error) {
        console.log("Error getting document:", error);
    });





    return (




    <div>

      {/*  { bookingStatus==="processing" ? :null}*/}

        <Button aria-controls="simple-menu" id="b-status-button" variant="contained" color="primary" aria-haspopup="true" onClick={handleClick}>
            {selectedStatus.selectedStatus}
        </Button>


        { bookingStatus==="new" ?<Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >


            <MenuItem  onClick={handleClose1('processing')}>Processing</MenuItem>
            <MenuItem onClick={handleClose1('cancelled')}>Cancelled</MenuItem>




        </Menu>:null}

        { bookingStatus==="processing" ?<Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >



            <MenuItem onClick={handleClose1('confirmed')}>Confirmed</MenuItem>
            <MenuItem onClick={handleClose1('cancelled')}>Cancelled</MenuItem>



        </Menu>:null}

        { bookingStatus==="confirmed" ?<Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >




            <MenuItem onClick={handleClose1('active')}>Active</MenuItem>
            <MenuItem onClick={handleClose1('cancelled')}>Cancelled</MenuItem>


        </Menu>:null}



        { bookingStatus==="active" ?<Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >




            <MenuItem onClick={handleClose1('completed')}>Completed</MenuItem>
            <MenuItem onClick={handleClose1('cancelled')}>Cancelled</MenuItem>


        </Menu>:null}

        { bookingStatus==="completed" ?<Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >





            <MenuItem onClick={handleClose1('cancelled')}>Cancelled</MenuItem>


        </Menu>:null}



        {/*<Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >


            <MenuItem  onClick={handleClose1('processing')}>Processing</MenuItem>
            <MenuItem onClick={handleClose1('confirmed')}>Confirmed</MenuItem>
            <MenuItem onClick={handleClose1('active')}>Active</MenuItem>
            <MenuItem onClick={handleClose1('completed')}>Completed</MenuItem>
            <MenuItem onClick={handleClose1('cancelled')}>Cancelled</MenuItem>



        </Menu>*/}

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity="success">
                Successfully Updated
            </Alert>
        </Snackbar>


    <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Event Name</TableCell>
                    <TableCell>Organizer name</TableCell>
                    <TableCell>Organization Name</TableCell>
                    <TableCell>Hall Type</TableCell>
                    <TableCell>Booking Date</TableCell>
                    <TableCell>Booking Time</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                    <TableRow key={bookingId}>
                        <TableCell>
                            <p id="e-name"/>
                        </TableCell>
                        <TableCell><p id="o-name"/></TableCell>
                        <TableCell><p id="o-c-name"/></TableCell>
                        <TableCell><p id="e-hallType"/></TableCell>
                        <TableCell><p id="booking-date"/></TableCell>
                        <TableCell><p id="booking-time"/></TableCell>
                    </TableRow>

            </TableBody>
        </Table>
    </TableContainer>

        <Box mt={1.5} width={400}>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Label Name</TableCell>
                        <TableCell>Price</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        blockArray.map(block =>

                            <TableRow><TableCell>{block.bLabel}</TableCell><TableCell>{block.bPrice}</TableCell></TableRow>

                        )
                    }



                   {/* <div id="event-blocks">

                    </div>*/}


                </TableBody>
            </Table>
        </TableContainer>
        </Box>

        <Card className={classes.root2}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Event  Images
                </Typography>

                <Box mt={1.5}>
                    <div id="event-img-gall">

                    </div>
                </Box>
            </CardContent>
        </Card>
        <Card className={classes.root2}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Layout  Images
                </Typography>

                <Box mt={1.5}>
                    <div id="layout-img-gall">

                    </div>
                </Box>
            </CardContent>
        </Card>

       {/* <Card className={classes.root2}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Event Blocks
                </Typography>



                <Box mt={1.5}>

                    <div id="event-blocks">

                    </div>

                    <Grid container spacing={3}>
                    {
                        blockArray.map(block =>

                            <Grid item xs={6} sm={2}>
                                <Paper className={classes.paper}>{block.bLabel+'\n'+block.bPrice}</Paper>
                            </Grid>

                        )
                    }
                    </Grid>
                </Box>
            </CardContent>
        </Card>*/}


    </div>
        );
}




