//import React from 'react';
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import firebase from '../services/firebase'

import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



function createPticipientBookings(bookingCount,labelName,labelPrice,orgStatus,docId) {
    return { bookingCount,labelName,labelPrice,orgStatus,docId };
}



const db = firebase.firestore();

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

var participantId = "";
var beId = "";





function getBookingData(beId,bCount,callback) {


    db.collection("booking").doc(beId).get().then((doc) => {

        var callBackString = {};
        if(doc.exists){

            var bData = doc.data();
            callBackString.beId = bData.b_e_id;
            callBackString.beName = bData.b_e_name;
            callBackString.beHallType = bData.b_e_hall_type;
            callback(callBackString);


        }else{



        }


    });




}
var bCount = 0;




export default function ParticipantBookingDetails() {

    const classes = useStyles();


    var [bArray,setBArray] = React.useState([]);



    participantId = useParams().pId;
    beId = useParams().id;

    useEffect(() => {



    });

  //  alert(beId);


    db.collection("booking").doc(beId).collection("event_blocks").where("b_participent_id","==",participantId).get().then((querySnapshot) => {




        if(querySnapshot.size > 0){


            var i = 0;
            bArray = [];
            querySnapshot.forEach((doc) =>{

                i++;

                var docId = doc.id;

                var bookingData = doc.data();
                var labelName = bookingData.b_lable_name;
                var labelPrice = bookingData.b_label_price;
                var orgStatus = bookingData.o_status;


                bArray.push(createPticipientBookings(i,labelName,labelPrice,orgStatus,docId))



            });
            setBArray(bArray)




        }else {


            alert("No booking found")

        }



    });








    return (<div>
        <Box mt={2}>
            <Typography color="textSecondary">
                Bookings
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell>SI.No</TableCell>
                            <TableCell>Label Name</TableCell>
                            <TableCell>Label Price</TableCell>
                            <TableCell>Booking Status</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bArray.map((row) => (

                            <TableRow key={row.docId}>

                                <TableCell>{row.bookingCount}</TableCell>
                                <TableCell>{row.labelName}</TableCell>
                                <TableCell>{row.labelPrice}</TableCell>
                                <TableCell>{row.orgStatus}</TableCell>


                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </div>);

}
