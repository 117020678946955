import React from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    LineSeries,
    BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { scaleBand } from '@devexpress/dx-chart-core';
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart';
import { ageStructure } from '../components/demochart';
import firebase from '../services/firebase'

import { sizing } from '@material-ui/system';

import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";


const db = firebase.firestore();
const storage = firebase.storage();


const ageStructure1 = [
    {
    state: 'Participants',
    young: 6.7,
    middle: 21.6,

}, {
    state: 'Visitor',
    young: 9.6,
    middle: 30.4,

}, {
    state: 'Organizers',
    young: 13.5,
    middle: 45,

}, {
    state: 'Events',
    young: 30,
    middle: 40.3,

}

];

function createChartData(state,young,middle) {

    return {state:state,young:young,middle:middle}
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: "#ffffff",
        height:'80px',

    },
    paper2: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height:'310px',
        width:'500px',
        marginLeft:'0%',
    },
    link:{

        textDecoration:'none',
        color:theme.palette.text.primary

    },
    root4: {
        position:'fixed',
        display: 'flex',
        justifyContent:'center',
        marginTop:'60px',
        marginLeft:'40%',

    },



}));

const Label = symbol => (props) => {
    const { text } = props;
    return (
        <ValueAxis.Label
            {...props}
            text={text + symbol}
        />
    );
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#37474f',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(eCount,eDate,eId,eName,oName,hallName,eStatus) {
    return { eCount,eDate,eId,eName,oName,hallName,eStatus };
}


function getParticipantCount(eventId,callBack) {

}


function Dashboard() {
    const classes = useStyles();


    const [value, setValue] = React.useState(0);

    const [showLoading,setShowLoading] = React.useState(false);

    const [bookingCount,setBookingCount] = React.useState("0");
    const [activeBookingCount,setActiveBookingCount] = React.useState("0");


    var [eventData,setEventData] = React.useState([]);
    const [chartData,setChartData] = React.useState([]);

    const userId = localStorage.getItem("userId") || '';
    const userName = localStorage.getItem("userName") || '';
    const selectedLanguage = localStorage.getItem("lang") || 'en';

    React.useEffect(()=>{








    });

    React.useState(()=>{



        db.collection("booking").where("o_id", "==",userId).where("b_e_status","==","active").get().then((querySnapshot) => {


            setShowLoading(true);
            var bookingCount = 0;

            querySnapshot.forEach((doc)=>{

                bookingCount++;
                const eventData = doc.data();
                const eventId = eventData.b_e_id;
                const eventName = eventData.b_e_name;

                var visitorCount = 0;
                var participantCount = [];
                db.collection("booking").doc(eventId).collection("events_all_leads").get().then((querySnapshot1)=>{


                    querySnapshot1.forEach((doc)=>{




                        visitorCount++;


                    });

                });


                db.collection("booking").doc(eventId).collection("event_blocks").get().then((querySnapshot2)=>{



                    querySnapshot2.forEach((doc2)=>{

                        const blockData = doc2.data();

                        const blockParticipantId = blockData.b_participent_id;


                        console.log(blockData);

                       // participantCount.push(blockParticipantId)


                        if (participantCount.includes(blockParticipantId)) {

                        } else {

                            participantCount.push(blockParticipantId)

                        }
                       /* if(blockParticipantId===""){

                        }else {

                            if (participantCount.indexOf(blockParticipantId) > -1) {
                                //In the array!
                            } else {

                                participantCount.push(blockParticipantId)

                            }

                        }*/





                    });


                });

                setTimeout(()=>{

                    //console.log(participantCount);

                    chartData.push(createChartData(eventName,visitorCount,participantCount.length));

                },1000);




                //console.log(eventName+"-------------------------");

            });
            setTimeout(()=>{

                setChartData(chartData);
                setShowLoading(false);
            },4000);



        });


        db.collection("booking").where("o_id", "==",userId).get().then((querySnapshot) => {


            var eCount = 0;
            var totalBookingCount = 0;
            eventData = [];
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);

                // eCount++;

                totalBookingCount++;


                var ids = doc.id;

                const data = doc.data();
                var eId = data.b_e_id;
                var eName = data.b_e_name;
                var oName = data.o_name;
                var hallName = data.h_e_hall_name;
                var eStatus = data.b_e_status;
                var bookingData = "";

                setShowLoading(true);

                eventData = [];

                db.collection("booking").doc(eId).collection("booking_dates").get().then((querySnapshot) => {

                    eCount++;

                    var dateCount = 0;
                    querySnapshot.forEach((doc)=>{

                        dateCount++;

                        if(dateCount<2){

                            const bDatas = doc.data();
                            const bDate = bDatas.booked_date;
                            bookingData+= bDate+" ";
                            console.log(bookingData);
                        }


                        eventData.push(createData(eCount,bookingData,eId,eName,oName,hallName,eStatus));
                    });



                    setTimeout(()=>{


                        setEventData(eventData);
                        setShowLoading(false);
                    },500);


                });

            });
            setBookingCount(totalBookingCount)


        });





        db.collection("booking").where("o_id", "==",userId).where("b_e_status","==","active").get().then((querySnapshot) => {




            setShowLoading(true);
            var totalActiveBookingCount = 0;
            querySnapshot.forEach((doc) => {

                totalActiveBookingCount++;

                const eventData = doc.data();

                const eventName = eventData.b_e_name;

              //  console.log(chartData);

            });




            setActiveBookingCount(totalActiveBookingCount)


        });




    });






    return (
        <div className={classes.root}>

            {showLoading===true ? <div className={classes.root4}>
                <CircularProgress  />
            </div>:null}


            <Grid container spacing={3}>


                <Grid item xs={6} sm={2}  className={classes.link} component={Link}  to={'/bookings/'}>
                    <Paper className={classes.paper} elevation={3}  style={{  backgroundColor: "#c0db80" }}>
                        <Typography variant="body1" gutterBottom>
                        { selectedLanguage==='ar' ? "مشارك":selectedLanguage==='en' ? "Total Booking":null}
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            {bookingCount}
                        </Typography>


                    </Paper>
                </Grid>
                <Grid item xs={6} sm={2} className={classes.link} component={Link}  to={'/bookings/'}  >
                    <Paper className={classes.paper} elevation={3}  style={{  backgroundColor: "#3ebfdb" }}>
                        <Typography variant="body1" gutterBottom>
                        { selectedLanguage==='ar' ? "الحجوزات":selectedLanguage==='en' ? "Active Bookings":null}
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            {activeBookingCount}
                        </Typography>


                    </Paper>
                </Grid>

            </Grid>
            <Box mt={2}>
                { chartData.length > 0 ?

                    <Paper className={classes.paper2} elevation={3}>
                        <Chart data={chartData} height="300">
                            <ArgumentScale factory={scaleBand}  />
                            <ArgumentAxis/>
                            <ValueAxis />

                            <BarSeries
                                valueField="young"
                                argumentField="state"
                                name="Young"
                            />
                            <BarSeries
                                valueField="middle"
                                argumentField="state"
                                name="Middle"
                            />

                            <Stack />
                        </Chart>

                    </Paper>


                    :null}

            </Box>
            <Box mt={2}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">

                                    { selectedLanguage==='ar' ? "رقم":selectedLanguage==='en' ? "SI.No":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">

                                    { selectedLanguage==='ar' ? "تاريخ الحدث":selectedLanguage==='en' ? "Event Date":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">

                                    { selectedLanguage==='ar' ? "اسم الحدث":selectedLanguage==='en' ? "Event Name":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">

                                    { selectedLanguage==='ar' ? "منظم":selectedLanguage==='en' ? "Organizer":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">

                                    { selectedLanguage==='ar' ? "صالة":selectedLanguage==='en' ? "Hall":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">

                                    { selectedLanguage==='ar' ? "الحالة":selectedLanguage==='en' ? "Status":null}
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eventData.map((row) => (
                                <StyledTableRow key={row.eId} component={Link} className={classes.link} to={'/eventDetails/'+row.eId}>
                                    <StyledTableCell align="left">{row.eCount}</StyledTableCell>
                                    <StyledTableCell align="left">{row.eDate}</StyledTableCell>
                                    <StyledTableCell align="left">{row.eName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.hallName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.eStatus}</StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </div>
    );
}


export default Dashboard;