//import React from 'react';
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import firebase from '../services/firebase'

import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(pId,pCompanyName,pName,pEmail,pGender,phone,vatNumber,pCrNumber,pBussinessType,pImage) {
    return { pId,pCompanyName,pName,pEmail,pGender,phone,vatNumber,pCrNumber,pBussinessType,pImage };
}

function createPticipientBookings(bookingCount,beId,bookingDate,beName,hallType) {
    return { bookingCount,beId,bookingDate,beName,hallType };
}

var rows = [];
var rows2 = [];

const db = firebase.firestore();




const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

var participantId = "";





function getBookingData(beId,bCount,callback) {


    db.collection("booking").doc(beId).get().then((doc) => {

        var callBackString = {};
        if(doc.exists){

            var bData = doc.data();
            callBackString.beId = bData.b_e_id;
            callBackString.beName = bData.b_e_name;
            callBackString.beHallType = bData.b_e_hall_type;
            callback(callBackString);


        }else{



        }


    });

    


}
var bCount = 0;

var pArray = []


export default function ParticipantDetails() {

    const classes = useStyles();



    var [pArray,setPArray] = React.useState([]);
    var [bArray,setBArray] = React.useState([]);

    var [data,setData] = React.useState({

        pId:"",
        pCompanyName:"",
        pName:"",
        pEmail:"",
        pGender:"",
        phone:"",
        vatNumber:"",
        pCrNumber:"",
        pBussinessType:"",
        pImage:""



    })

    participantId = useParams().id;


    useEffect(() => {

       // setBArray(pArray)

    });


    db.collection("participient").doc(participantId).get().then((doc) => {

        rows= [];
        pArray = [];
        if(doc.exists){

            var ids = doc.id;
            console.log(ids);

            const data = doc.data();
            var pId = data.p_id;
            var pCompanyName = data.p_company_name;
            var pName = data.p_person_name;
            var pEmail = data.p_email;
            var pGender = data.p_gender;
            var phone = data.p_phone;
            var vatNumber = data.p_vat_number;
            var pCrNumber = data.p_cr_number;
            var pBussinessType = data.p_bussiness_type;
            var pImage = data.p_image;



            pArray.push(createData(pId,pCompanyName,pName,pEmail,pGender,phone,vatNumber,pCrNumber,pBussinessType,pImage));

            setData({pId:pId,pCompanyName:pCompanyName,pName:pName,pEmail:pEmail,pGender:pGender,phone:phone,vatNumber:vatNumber,pCrNumber:pCrNumber,pBussinessType:pBussinessType,pImage:pImage})




        }else{


        }
       // setBArray(pArray)


    });


    db.collection("participient").doc(participantId).collection("my_bookings").get().then((querySnapshot) => {

       
      

        if(querySnapshot.size > 0){

           
            var i = 0;
            bArray = [];
            querySnapshot.forEach((doc) =>{

                i++;   

                var docId = doc.id;

                var bookingData = doc.data();
                var bookingsId = bookingData.b_e_id;
                var bookingsEventName = bookingData.b_e_name;
                var bookingSHallType = bookingData.b_e_hall_type;
                var bookingDate = bookingData.b_e_date;



                bArray.push(createPticipientBookings(i,bookingsId,bookingDate,bookingsEventName,bookingSHallType))

                /*getBookingData(bookingsId,"0",(callback)=>{

                    bArray.push(createPticipientBookings(i,bookingsId,"",callback.beName,callback.beHallType))
                    setBArray(bArray)


                })*/


    
               
            });
            setBArray(bArray)




        }else {


             alert("No booking found")

        }

        

    });








    return (<div>

        <TableContainer component={Paper}><Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">CompanyName</StyledTableCell>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Phone</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Gender</StyledTableCell>
                    <StyledTableCell align="left">Business Type</StyledTableCell>
                    <StyledTableCell align="left">CR Number</StyledTableCell>
                    <StyledTableCell align="left">VAT Number</StyledTableCell>
                    <StyledTableCell align="left">Image</StyledTableCell>

                </TableRow>
            </TableHead>
            <TableBody>

                <StyledTableRow key={data.pId}>
                    <StyledTableCell align="left">{data.pCompanyName}</StyledTableCell>
                    <StyledTableCell align="left">{data.pName}</StyledTableCell>
                    <StyledTableCell align="left">{data.phone}</StyledTableCell>
                    <StyledTableCell align="left">{data.pEmail}</StyledTableCell>
                    <StyledTableCell align="left">{data.pGender}</StyledTableCell>
                    <StyledTableCell align="left">{data.pBussinessType}</StyledTableCell>
                    <StyledTableCell align="left">{data.pCrNumber}</StyledTableCell>
                    <StyledTableCell align="left">{data.vatNumber}</StyledTableCell>
                    <StyledTableCell align="left">
                        <a href={data.pImage} target="_blank" style={{ textDecoration:'none'}}><Button variant="contained">View</Button></a>
                    </StyledTableCell>
                </StyledTableRow>

               {/* {pArray.map((row) => (
                    <StyledTableRow key={row.name}>
                        <StyledTableCell align="left">{data[0].pCompanyName}</StyledTableCell>
                        <StyledTableCell align="left">{row.pName}</StyledTableCell>
                        <StyledTableCell align="left">{row.phone}</StyledTableCell>
                        <StyledTableCell align="left">{row.pEmail}</StyledTableCell>
                        <StyledTableCell align="left">{row.pGender}</StyledTableCell>
                        <StyledTableCell align="left">{row.pBussinessType}</StyledTableCell>
                        <StyledTableCell align="left">{row.pCrNumber}</StyledTableCell>
                        <StyledTableCell align="left">{row.vatNumber}</StyledTableCell>
                        <StyledTableCell align="left">
                            <a href={row.pImage} target="_blank" style={{ textDecoration:'none'}}><Button variant="contained">View</Button></a>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}*/}
            </TableBody>
        </Table>
        </TableContainer>
        <Box mt={2}>
            <Typography color="textSecondary">
                Bookings
            </Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell>SI.No</TableCell>
                            <TableCell>Booking Date</TableCell>
                            <TableCell>Event Name</TableCell>
                            <TableCell>Hall Type</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bArray.map((row) => (

                            <TableRow key={row.beId} component={Link} style={{ textDecoration:'none'}} to={'/participantBookingDetails/'+row.beId+'/'+participantId}>

                                <TableCell>{row.bookingCount}</TableCell>
                                <TableCell>{row.bookingDate}</TableCell>
                                <TableCell>{row.beName}</TableCell>
                                <TableCell>{row.hallType}</TableCell>


                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </div>);

}
