import React from 'react';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from '../services/firebase'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';


import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";


const onSave = (workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
};



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#37474f',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },



}))(TableRow);

function createData(eCount,eDate,eId,eName,oName,hallName,eStatus) {
    return { eCount,eDate,eId,eName,oName,hallName,eStatus };
}

var rows = [];

const db = firebase.firestore();






const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },
        root3: {

            display: 'inline-block',
            position: 'fixed',
            paddingLeft:'40%',
            marginTop:'100px',
        },
        root4: {
            position:'fixed',
            display: 'flex',
            justifyContent:'center',
            marginTop:'60px',
            marginLeft:'40%',

        },



    }),
);

function getBookingDate(beId,bCount,callback) {


    db.collection("booking").doc(beId).collection("booking_dates").get().then(function (querySnapshot) {

        var callBackString = {};
        var dateCount = 0;
        querySnapshot.forEach(function (doc) {

            dateCount++;

            if(dateCount<2){

                var bDate = doc.data();
                callBackString.bookingDate = bDate.booked_date;

            }



        });
        callBackString.bCount = bCount;
        callback(callBackString);



    });


}








export default function Events() {
    const classes = useStyles();



   // var [state,setState] = React.useState([]);
    const [showLoading,setShowLoading] = React.useState(false);


    var [eventData,setEventData] = React.useState([]);//


    const userId = localStorage.getItem("userId") || '';
    const userName = localStorage.getItem("userName") || '';
    const selectedLanguage = localStorage.getItem("lang") || 'en';



    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        saveAs.saveAs(data, fileName + fileExtension);
    };

    const exportDataAsExcel = () => {


         exportToCSV(eventData,"event")

    };


    React.useState(()=>{

        db.collection("booking").where("o_id", "==",userId).get().then((querySnapshot) => {


            var eCount = 0;
            var a = 0;
            setShowLoading(true);
            eventData = [];
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);

                // eCount++;
                a++;
                var ids = doc.id;

                const data = doc.data();
                var eId = data.b_e_id;
                var eName = data.b_e_name;
                var oName = data.o_name;
                var hallName = data.h_e_hall_name;
                var eStatus = data.b_e_status;
                var bookingData = "";
                var startDate = data.e_startDate.toDate();
                var endtDate = data.e_endDate.toDate();

                var newStartDate = new Date(startDate).getDate()+"/"+(parseInt(new Date(startDate).getMonth())+1)+"/"+new Date(startDate).getFullYear();
                var newEndtDate = new Date(endtDate).getDate()+"/"+(parseInt(new Date(endtDate).getMonth())+1)+"/"+new Date(endtDate).getFullYear();

                eventData.push(createData(a,newStartDate+" to "+newEndtDate,eId,eName,oName,hallName,eStatus))

               /* setShowLoading(true);


                eventData = [];

                db.collection("booking").doc(eId).collection("booking_dates").get().then((querySnapshot) => {

                    eCount++;

                    var dateCount = 0;
                    querySnapshot.forEach((doc)=>{

                        dateCount++;

                        if(dateCount<2){

                            const bDatas = doc.data();
                            const bDate = bDatas.booked_date;
                            bookingData+= bDate+" ";
                            console.log(bookingData);
                        }


                        eventData.push(createData(eCount,bookingData,eId,eName,oName,hallName,eStatus));
                      //

                    });



                    setTimeout(()=>{


                        setEventData(eventData);
                        setShowLoading(false);
                    },1000);


                });*/

            });
            setTimeout(()=>{


                setEventData(eventData);
                setShowLoading(false);//
            },1000);







        });


    });








    return (<div>

        <Autocomplete
            debug
            id="debug"
            options={eventData}
            getOptionLabel={(option) => option.eName.toString()}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Search" />}
            onChange={(event, pData) => {


                if(pData!==null){

                    eventData = [];

                    console.log(pData);
                    //eCount,eDate,eId,eName,oName,hallName,eStatus
                    eventData.push(createData(pData.eCount,pData.eDate,pData.eId,pData.eName,pData.oName,pData.hallName,pData.eStatus));

                    setEventData(eventData)

                }else {

                    db.collection("booking").where("o_id", "==",userId).get().then((querySnapshot) => {

                        var eCount = 0;
                        var a = 0;
                        setShowLoading(true);
                        eventData = [];
                        querySnapshot.forEach((doc) => {
                            console.log(`${doc.id} => ${doc.data()}`);


                            a++;
                            var ids = doc.id;

                            const data = doc.data();
                            var eId = data.b_e_id;
                            var eName = data.b_e_name;
                            var oName = data.o_name;
                            var hallName = data.h_e_hall_name;
                            var eStatus = data.b_e_status;
                            var bookingData = "";
                            var startDate = data.e_startDate.toDate();
                            var endtDate = data.e_endDate.toDate();

                            var newStartDate = new Date(startDate).getDate()+"/"+(parseInt(new Date(startDate).getMonth())+1)+"/"+new Date(startDate).getFullYear();
                            var newEndtDate = new Date(endtDate).getDate()+"/"+(parseInt(new Date(endtDate).getMonth())+1)+"/"+new Date(endtDate).getFullYear();

                            eventData.push(createData(a,newStartDate+" to "+newEndtDate,eId,eName,oName,hallName,eStatus))



                        });
                        setTimeout(()=>{


                            setEventData(eventData);
                            setShowLoading(false);//
                        },1000);







                    });


                }







            }}


        />


            <Button variant="contained" color="primary" style={{ float: 'right',marginBottom:'1px',marginTop:'-32px',textTransform:'none' }} onClick={exportDataAsExcel}>

                { selectedLanguage==='ar' ? "تصدير Excel":selectedLanguage==='en' ? "Export Excel":null}
            </Button>


            <Box mt={2}>
            <TableContainer component={Paper}>

            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">
                            { selectedLanguage==='ar' ? "رقم":selectedLanguage==='en' ? "SI.No":null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            { selectedLanguage==='ar' ? "تاريخ الحدث":selectedLanguage==='en' ? "Event Date":null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            { selectedLanguage==='ar' ? "اسم الحدث":selectedLanguage==='en' ? "Event Name":null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            { selectedLanguage==='ar' ? "منظم":selectedLanguage==='en' ? "Organizer":null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            { selectedLanguage==='ar' ? "صالة":selectedLanguage==='en' ? "Hall":null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            { selectedLanguage==='ar' ? "الحالة":selectedLanguage==='en' ? "Status":null}
                        </StyledTableCell>
                       {/* <StyledTableCell align="left">

                            { selectedLanguage==='ar' ? "عمل":selectedLanguage==='en' ? "Action":null}
                        </StyledTableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventData.map((row) => (
                        <StyledTableRow key={row.eId} component={Link} className={classes.link} to={'/eventDetails/'+row.eId+"/"+row.eStatus}>
                            <StyledTableCell align="left">{row.eCount}</StyledTableCell>
                            <StyledTableCell align="left">{row.eDate}</StyledTableCell>
                            <StyledTableCell align="left">{row.eName}</StyledTableCell>
                            <StyledTableCell align="left">{row.oName}</StyledTableCell>
                            <StyledTableCell align="left">{row.hallName}</StyledTableCell>
                            <StyledTableCell align="left">{row.eStatus}</StyledTableCell>
                            {/*<StyledTableCell align="left">

                                {row.eStatus === "new" ? <Button color="primary" component={Link} className={classes.link} to={'/eventEdit/'+row.eId}>Edit</Button>:null}
                                {row.eStatus === "processing" ? <Button color="primary" component={Link} className={classes.link} to={'/eventEdit/'+row.eId}>Edit</Button>:null}
                                {row.eStatus === "confirm" ? <Button color="primary" component={Link} className={classes.link} to={'/eventEdit/'+row.eId}>Edit</Button>:null}


                            </StyledTableCell>*/}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>

                {showLoading===true ? <div className={classes.root4}>
                    <CircularProgress  />
                </div>:null}



            </TableContainer>
            </Box>
    </div>);
}
