import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';






function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '32ch',
            },
        },
        root2: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            paddingLeft:'30%',
            paddingTop:'15%',
            zIndex:10,
            position: 'fixed',
            visibility:'hidden'

        },
        root3:{
            width: '45ch',
            float:'right'
        },

    }),
);

const db = firebase.firestore();
const storage = firebase.storage();


var userIds = "";
var usernames = "";
var passwords = "";







export default function Profile() {
    const classes = useStyles();

    const [showAlert,setAlert] = React.useState(false);

    const [state,setState] = React.useState(false);

    const userId = localStorage.getItem("userId") || '';
    const userName = localStorage.getItem("userName") || '';
    const selectedLanguage = localStorage.getItem("lang") || 'en';

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const [orgData,setOrgData] = React.useState({
        username:usernames,
        password:passwords,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const setUserNamePassword = () => {

    }



    const handleChange = (event) => {

        event.preventDefault();

        setOrgData({username:orgData.username,password:event.target.value})


    };


    const sendUpdateData = e => {

        e.preventDefault();


        db.collection("organiser").doc(userId).update({

            o_login_password:orgData.password

        }).then(()=>{

            setAlert(true);

            setTimeout(()=>{

                setAlert(false)

            },3000)

        });



    };




    React.useState(()=>{

        db.collection("organiser").doc(userId).get().then((doc)=>{

            if(doc.exists){

                const orgData  = doc.data();
                const userName = orgData.o_email;
                const password = orgData.o_login_password;

                usernames = userName;
                passwords = password;

                setOrgData({username:userName,password:password});

            }



        });




    });






    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={sendUpdateData}>
            <div>
                <div className={classes.root2} id="p-bar">
                    <CircularProgress />
                </div>

                <div className={classes.root3}>
                    {showAlert ? <Alert  severity="success" autoHideDuration={2000}>Successfully Change Password</Alert>:null}

                </div>
                <Box mt={2}>
                    <FormGroup row>
                        <Box>
                        <TextField
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "اسم المستخدم":selectedLanguage==='en' ? "Username":null}
                            type="text"
                            name="username"
                            value={orgData.username}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                        />
                        </Box>
                    </FormGroup>

                    <FormGroup row>
                        <Box pl={1} width={256}>
                        <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={orgData.password}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }

                        />
                        </Box>

                    </FormGroup>
                </Box>


            </div>

            <div>
                <Box mt={3} ml={1}>
                    <Button type="submit" variant="contained" color="primary">

                        { selectedLanguage==='ar' ? "تحديث":selectedLanguage==='en' ? "UPDATE":null}
                    </Button>
                </Box>
            </div>
        </form>
    );
}