import React from 'react';
//import { withStyles, makeStyles } from '@material-ui/core/styles';
import { makeStyles,withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";

import firebase from '../services/firebase'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);



const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(oCompanyName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType,address,oArea,oCity,expDate,loginMail,loginPassword,crDocument) {
    return { oCompanyName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType,address,oArea,oCity,expDate,loginMail,loginPassword,crDocument };
}

function createBookingData(bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,bookingDate) {
    return { bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,bookingDate };
}

function createEventBlocks(blockCount,blockId,blockLabel,blockPrice,participantId,participantNote,participantStatus,bookingTime,oStatus) {

    return{blockCount,blockId,blockLabel,blockPrice,participantId,participantNote,participantStatus,bookingTime,oStatus}
}



var rows = [];
var rows2 = [];
var rows3 = [];
const db = firebase.firestore();

const ref = React.createRef();


function getOrganiserData(organiserId) {

    db.collection("organiser").doc(organiserId).get().then((doc) => {

        rows = [];

        if (doc.exists) {

            var ids = doc.id;
            console.log(ids);

            const data = doc.data();
            var oId = data.o_id;
            var oName = data.o_name;
            var cName = data.o_c_name;
            var oEmail = data.o_email;
            var oGender = data.o_gender;
            var phone = data.o_mobilenumber;
            var vatNumber = data.o_vat_number;
            var oCrNumber = data.o_cr_number;
            var oBussinessType = data.o_type;
            var oAddress = data.o_address;
            var line1 = oAddress.line1;
            var line2 = oAddress.line2;
            var line3 = oAddress.line3;
            var oArea = data.o_area;
            var oCity = data.o_city;
            var expDate = data.o_cr_expiry_date;
            var loginMail = data.o_login_mail;
            var loginPassword = data.o_login_password;
            var crDocument = data.o_cr_document;

            var totalAddress = line1+","+line2+","+line3;

            rows.push(createData(cName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType,totalAddress,oArea,oCity,expDate,loginMail,loginPassword,crDocument))


        }




        /* querySnapshot.forEach((doc) => {
         console.log(`${doc.id} => ${doc.data()}`);


         });*/

    });


}

function getOrganizerBookings(beId) {



    db.collection("booking").where("b_e_id", "==", beId).get().then(function (querySnapshot) {

        var bookingCount = 0;
        rows2=[];
        querySnapshot.forEach(function (doc) {

            bookingCount++;
            const data = doc.data();
            var beId = data.b_e_id;
            var beName = data.b_e_name;
            var beStatus = data.b_e_status;
            var beType = data.b_e_type;
            var organizationName = data.o_c_name;
            var oName = data.o_name;
            var hallType = data.b_e_hall_type;
            var isLive = data.is_live;

 


            getBookingDate(beId,bookingCount,function (callBack) {

                rows2 = [];
                rows2.push(createBookingData(bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,callBack.bookingDate))





            });


        });


    }).catch(function (error) {

        console.log("Error getting documents: ", error);

    });


    db.collection("booking").doc(beId).collection("event_blocks").get().then(function (querySnapshot) {


        var blockCount = 0;
        rows3 = [];
        querySnapshot.forEach(function (doc) {

            blockCount++;
            const blockData = doc.data();

            const blockId = blockData.b_id;
            const blockLabel = blockData.b_lable_name;
            const blockPrice = blockData.b_label_price;
            const participantId = blockData.b_participent_id;
            const participantNote = blockData.b_participent_note;
            const participantStatus = blockData.b_participient_status;
            const bookingTime = blockData.timeStamp.toDate();
            const oStatus = blockData.o_status;

            const day = bookingTime.getDate();
            const month = parseFloat(bookingTime.getMonth())+1;
            const year = bookingTime.getFullYear();
            const newBookingTime = day+"/"+month+"/"+year;



            rows3.push(createEventBlocks(blockCount,blockId,blockLabel,blockPrice,participantId,participantNote,participantStatus,newBookingTime,oStatus))


        });





    });



}

function getBookingDate(beId,bCount,callback) {


    db.collection("booking").doc(beId).collection("booking_dates").get().then(function (querySnapshot) {

        var callBackString = {};
        querySnapshot.forEach(function (doc) {


            var bDate = doc.data();
            callBackString.bookingDate = bDate.booked_date;

        });
        callback(callBackString);



    });


}



const useStyles = makeStyles((theme: Theme) => {

    createStyles({

        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary
        },
        link2:{
            textDecoration:'none',
            color:theme.palette.text.primary
        }

    });


});

var orgId = "";
var beId = "";





export default function OrganizerBookingDetails() {

    let  {id}  = useParams();
    beId = useParams().id;
    orgId = useParams().oId;

    const [state,setState] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClick3 = () => {
        setOpen(true);
    };

    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleChangeStatus = (event) => {

        const selectedData = event.target.value.split("|");

        const blockId = selectedData[1];
        const selectedStatus123 = selectedData[0];

       // alert(blockId + "  " + selectedStatus123+ " " +beId);

        setState({
            ["select-status"+blockId]:selectedStatus123
        });

        db.collection("booking").doc(beId).collection("event_blocks").doc(blockId).update({

            "o_status":selectedStatus123


        }).then(function () {

            handleClick3();

        });





    };





    getOrganiserData(orgId);
    getOrganizerBookings(beId);

    return (

        <div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity="success">
                    Successfully Updated
                </Alert>
            </Snackbar>

            <Box mt={2}  >
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">SI.No</StyledTableCell>
                                <StyledTableCell align="left">Label Name</StyledTableCell>
                                <StyledTableCell align="left">Label Price</StyledTableCell>
                                <StyledTableCell align="left">Participant Name</StyledTableCell>
                                <StyledTableCell align="left">Participant Note</StyledTableCell>
                                <StyledTableCell align="left">Participant Status</StyledTableCell>
                                <StyledTableCell align="left">Booking Time</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows3.map((row) => (




                                <StyledTableRow key={row.blockId}>
                                    <StyledTableCell align="left">{row.blockCount}</StyledTableCell>
                                    <StyledTableCell align="left">{row.blockLabel}</StyledTableCell>
                                    <StyledTableCell align="left">{row.blockPrice}</StyledTableCell>
                                    <StyledTableCell align="left">{row.participantId}</StyledTableCell>
                                    <StyledTableCell align="left">{row.participantNote}</StyledTableCell>
                                    <StyledTableCell align="left">{row.participantStatus}</StyledTableCell>
                                    <StyledTableCell align="left">{row.bookingTime}</StyledTableCell>
                                    <StyledTableCell align="left">

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id={"select-status"+row.blockId}
                                            onChange={handleChangeStatus}
                                            defaultValue={row.oStatus}
                                        >
                                            <MenuItem value={"processing"+"|"+row.blockId+""}>Processing</MenuItem>
                                            <MenuItem value={"confirm"+"|"+row.blockId+""}>Confirm</MenuItem>
                                            <MenuItem value={"completed"+"|"+row.blockId+""}>Completed</MenuItem>
                                            <MenuItem value={"cancelled"+"|"+row.blockId+""}>Cancelled</MenuItem>

                                        </Select>
                                    </StyledTableCell>
                                </StyledTableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
}
