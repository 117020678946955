import React from 'react';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from '../services/firebase'

import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(pId,pCompanyName,pName,pEmail,pGender,phone,vatNumber,pCrNumber,pBussinessType) {
  return { pId,pCompanyName,pName,pEmail,pGender,phone,vatNumber,pCrNumber,pBussinessType };
}

var rows = [];

const db = firebase.firestore();







/*const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
    link:{

        textDecoration:'none',
        color:theme.palette.text.primary

    },
});*/

const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },


    }),
);



export default function CustomizedTablesp() {
  const classes = useStyles();

  const [state,setState] = React.useState();



    db.collection("participient").get().then((querySnapshot) => {

        rows= [];
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);

            var ids = doc.id;
            console.log(ids);

            const data = doc.data();
            var pId = data.p_id;
            var pCompanyName = data.p_company_name;
            var pName = data.p_person_name;
            var pEmail = data.p_email;
            var pGender = data.p_gender;
            var phone = data.p_phone;
            var vatNumber = data.p_vat_number;
            var pCrNumber = data.p_cr_number;
            var pBussinessType = data.p_bussiness_type;



            rows.push(createData(pId,pCompanyName,pName,pEmail,pGender,phone,vatNumber,pCrNumber,pBussinessType))




        });
        setState({
            rows
        })





    });



  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
              <StyledTableCell align="left">CompanyName</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Phone</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Gender</StyledTableCell>
              <StyledTableCell align="left">Business Type</StyledTableCell>
              <StyledTableCell align="left">CR Number</StyledTableCell>
              <StyledTableCell align="left">VAT Number</StyledTableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name} component={Link} className={classes.link} to={'/participantsDetails/'+row.pId}>
                <StyledTableCell align="left">{row.pCompanyName}</StyledTableCell>
                <StyledTableCell align="left">{row.pName}</StyledTableCell>
                <StyledTableCell align="left">{row.phone}</StyledTableCell>
                <StyledTableCell align="left">{row.pEmail}</StyledTableCell>
                <StyledTableCell align="left">{row.pGender}</StyledTableCell>
                <StyledTableCell align="left">{row.pBussinessType}</StyledTableCell>
                <StyledTableCell align="left">{row.pCrNumber}</StyledTableCell>
                <StyledTableCell align="left">{row.vatNumber}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
