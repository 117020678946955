import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import swal from 'sweetalert';
import MultipleDatePicker from 'react-multiple-datepicker'

import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';



const db = firebase.firestore();
const storage = firebase.storage();



function Alert(props) {

    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        table: {
            minWidth: '300px',
            width:'300px'
        },
        root2: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            paddingLeft:'30%',
            paddingTop:'15%',
            zIndex:10,
            position: 'fixed',
            visibility:'hidden'

        },
        root3:{
            width: '45ch',
            float:'right'
        },
        root4: {
            position:'fixed',
            display: 'flex',
            justifyContent:'center',
            marginTop:'60px',
            marginLeft:'40%',

        },
        mPicker:{
            width:'300px',
            height:'55px',
        }

    }),

);

//var organiser = [];
//const halls = [];
//const adOnServices = [];
const selectedAdOnService = [];
const hallBlocks = [];

const userId = localStorage.getItem("userId") || '';
const userName = localStorage.getItem("userName") || '';

function createOrganiser(oId,OName,oCName) {

    return{value:oId,label:OName,oCName:oCName}

}

function createHall(hId,hName,hPrice,hType,hInUnCharge) {

    return{value:hId,label:hName,price:hPrice,type:hType,hInUnCharge:hInUnCharge}
}

function createAdOnService(adSId,adSType,adSAmount) {

    return{value:adSId,label:adSType,price:adSAmount}
}

function createBlocks(lName,lPrice) {

    return{lName:lName,lPrice:lPrice}
}

function createBooth(count,boothName,boothPrice) {

    return{count,boothName,boothPrice}

}








function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


const avatarImageStyle = {
    width: 50,
    height: 50,
};

var organizerId = [{organizerId:""}];
var organizerName = [{ organizerName:""}];
var organizerCName = [{organizerCName:""}];
var selectedEventDate = [{ selectedEventDate:""}];
var selectedHallId = [{selectedHallId:""}]
var selectedHall = [{ selectedHall:""}];
var selectedHallType =  [{selectedHallType:""}];
var selectedHallPrice = [{selectedHallPrice:""}];
var selectedHallInUnincharge = [{selectedHallInUnincharge:""}];
var eventName = [{ eventName:""}];
var startTime = [{ startTime:""}];
var endTime = [{ endTime:""}];
var eventFiles = [{ eventFiles:"" }];
var layoutFiles = [{ layoutFiles:"" }];
var eventDescription = [{eventDescription:""}];
var hallPrice = [{hallPrice:""}];
var addOnServiceTotal = [{addOnServiceTotal:""}];
var numberOfInstallUninstallDays = [{numberOfInstallUninstallDays:""}];


function checkBookingSlot(hallId,sDate,eDate,callback) {


    db.collection("hall").doc(hallId).collection("booked_dates").get().then(function (querySnapShots) {

            var callBackString = {};
            querySnapShots.forEach(function (doc) {

                const hallDateData = doc.data();
                var eStartDate = hallDateData.e_startDate.toDate();
                var eEndDate = hallDateData.e_endDate.toDate();



                if(new Date(eStartDate).getTime() >= new Date(sDate).getTime() && new Date(eEndDate).getTime() <= new Date(eDate).getTime()){

                    console.log(new Date(eStartDate).getDate() + " " +new Date(eEndDate).getDate());
                    console.log(new Date(sDate).getDate() + " " +new Date(eDate).getDate());
                    console.log("alreadybooked");
                    callBackString.status = false;
                    callBackString.sDate = new Date(eStartDate);
                    callBackString.eDate = new Date(eEndDate);

                }else {
                    console.log(new Date(eStartDate).getDate() + " " +new Date(eEndDate).getDate());
                    console.log(new Date(sDate).getDate() + " " +new Date(eDate).getDate());
                    console.log("not booked");
                    callBackString.status = true;
                    callBackString.sDate = new Date(eStartDate);
                    callBackString.eDate = new Date(eEndDate);
                }


            });
            callback(callBackString);


    })

}




export default function CreateOrganizer() {
    const classes = useStyles();

    const [showAlert,setAlert] = React.useState(false);
    const [alertMessage,setAlertMessage] = React.useState("");
    const [busyDates,setBusyDate] = React.useState([]);
    const [showLoading,setShowLoading] = React.useState(false);

    const userId = localStorage.getItem("userId") || '';
    const userName = localStorage.getItem("userName") || '';
    const selectedLanguage = localStorage.getItem("lang") || 'en';

    const [organiser,setOrganizers] = React.useState([]);
    const [halls,setHalls] = React.useState([]);
    const [adOnServices,setAdonServices] = React.useState([]);

    const [organizerName,setOrganizerName] = React.useState("");

    const [selectedDate, setSelectedDate] = React.useState([]);
    const [selectedStartTime, setSelectedStartTime] = React.useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = React.useState(new Date());

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');


    const [eStartDate,seteStartDate] = React.useState("");
    const [eEndDate,seteEndDate] = React.useState("");

    const [subTotalPrice,setSubTotalPrice] = React.useState(0.0);

    const currentDate = new Date();

    const [newselectedHallPrice,newsetSelectedHallPrice] = React.useState("0");
    const [newinstallUnistallCharge,setnewinstallUnistallCharge] = React.useState("0");
    const [numberOfDaysSelected,setnumberOfDaysSelected] = React.useState("0");
    const [numberOfInUnSelected,setnumberOfInUnSelected] = React.useState("0");
    const [totalAddonServiceAmount,settotalAddonServiceAmount] = React.useState("0");

    const [validDateRange,setValidDateRange] = React.useState(false);


    var [state, setState] = React.useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            showDateDisplay:false,
            key: 'selection'
        }
    ]);





    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };




    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);




    var hallTotalPrice = 0;
    var adServiceTotal = 0;
    var hallSinglePrice = 0;





    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      //  setGender(event.target.value);



        var checkedData = event.target.value;
        var checkedIdTypeAmount = checkedData.split("|");
        var adSId = checkedIdTypeAmount[0];
        var adStype = checkedIdTypeAmount[1];
        var adSAmount = checkedIdTypeAmount[2];


        console.log(event.target.checked);
        if(event.target.checked){




            selectedAdOnService.push(createAdOnService(adSId,adStype,adSAmount));
            console.log(selectedAdOnService.length);

            adServiceTotal = parseFloat(adServiceTotal)+parseFloat(adSAmount);


            var dataString = "";
            var addonTotal = 0
            selectedAdOnService.filter(function (option) {

               // alert(option.value);

                dataString+=option.value+" :"+option.price+"<br>";

                document.getElementById("ads-service-price").innerHTML = "Add on service <br>"+dataString;

                addonTotal = parseFloat(addonTotal)+parseFloat(option.price);
                settotalAddonServiceAmount(addonTotal)


            });
            hallTotalPrice = parseFloat(hallTotalPrice)+parseFloat(adSAmount);
           // document.getElementById("total-price").innerHTML = "Total Price : SR "+hallTotalPrice;



        }else {


            selectedAdOnService.splice(selectedAdOnService.findIndex(v => v.value === adSId), 1);

            var dataString = "";
            var addonTotal = 0
            selectedAdOnService.filter(function (option) {

                // alert(option.value);

                dataString+=option.value+" :"+option.price+"<br>";

                document.getElementById("ads-service-price").innerHTML = "Add on service <br>"+dataString;

                addonTotal = parseFloat(addonTotal)+parseFloat(option.price);
                settotalAddonServiceAmount(addonTotal)

            });


            if(selectedAdOnService.length <=0){

                document.getElementById("ads-service-price").innerHTML = "Add on service <br>";
                adServiceTotal = 0;

                adServiceTotal = 0;
                settotalAddonServiceAmount("0")

            }

            hallTotalPrice = parseFloat(hallTotalPrice)-parseFloat(adSAmount);

           // document.getElementById("total-price").innerHTML = "Total Price : SR "+hallTotalPrice;

        }






    };




    const handleDateChange = (date) => {

        const hId = selectedHallId[0].selectedHallId;

        if(hId===""){


            swal("Select Hall First");





        }else {

            const newSelectedDates = date.toString().split(",");
            for(var i=0;i<newSelectedDates.length;i++){

                var selDate = new Date(newSelectedDates[i]);

                var day = parseInt(selDate.getDate());
                var month = parseInt(selDate.getMonth())+1;
                var year = parseInt(selDate.getFullYear());




                console.log(day+"/"+month+"/"+year);
                const checkSelectedDate = day+"/"+month+"/"+year;
                selectedEventDate[0].selectedEventDate = checkSelectedDate;



                db.collection("hall")
                    .doc(hId)
                    .collection("booked_dates")
                    .where("booked_date","==",checkSelectedDate)
                    .get()
                    .then(function(querySnapshot) {


                        if(querySnapshot.size===0){

                            if(selectedDate.indexOf(checkSelectedDate) !== -1){



                            } else{


                                selectedDate.push(checkSelectedDate)
                            }





                        }else{


                        }
                        setSelectedDate(setSelectedDate);

                        querySnapshot.forEach(function(doc) {




                            console.log(doc.id, " => ", doc.data());

                            const bDates = doc.data().booked_date;


                            var a = "";
                            if(bDates===checkSelectedDate){

                                // alert(checkSelectedDate+' Date Already  booked')

                                a += checkSelectedDate;



                                if(busyDates.indexOf(checkSelectedDate) !== -1){



                                } else{

                                    //alert("Value does not exists!")



                                    busyDates.push(" "+checkSelectedDate+" ");
                                    setBusyDate(busyDates);
                                }



                                //  swal(''+busyDates.toString()+' Date Already  booked');

                                setAlert(true);
                                setAlertMessage('\n'+busyDates.toString()+' Date Already  booked');



                                setTimeout(()=>{

                                    setAlert(false);
                                    setAlertMessage("");
                                    setBusyDate([]);
                                },5000)



                            }else {



                            }





                        });
                    }).catch(function(error) {
                    console.log("Error getting documents: ", error);
                });




            }

        }


       /* setSelectedDate(date);




        var selDate = new Date(date);

        var day = parseInt(selDate.getDate());
        var month = parseInt(selDate.getMonth())+1;
        var year = parseInt(selDate.getFullYear());

        console.log(day+"/"+month+"/"+year);
        var checkSelectedDate = day+"/"+month+"/"+year;
        selectedEventDate[0].selectedEventDate = checkSelectedDate;

        db.collection("hall").get().then((querySnapshot) => {


            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);

                var ids = doc.id;

                db.collection("hall").doc(ids).collection("booked_dates").where("booked_date", "==",checkSelectedDate)
                    .get()
                    .then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {

                            console.log(doc.id, " => ", doc.data());
                            alert('Date Already  booked')


                        });
                    }).catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });


            });

        });*/



    };





    const updateOrganizerInput = (event: React.ChangeEvent<HTMLInputElement>) => {

          var organizerData = event.target.value.split("|");

         // alert(organizerData[0]);

          organizerId[0].organizerId = organizerData[0];
          organizerName[0].organizerName = organizerData[1];
          organizerCName[0].organizerCName = organizerData[2];

    };
    const updateHallInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        var hallData = event.target.value.split("|");
        selectedHallId[0].selectedHallId = hallData[0];
        selectedHall[0].selectedHall = hallData[1];
        hallPrice[0].hallPrice = hallData[2];
        selectedHallType[0].selectedHallType = hallData[3];
        selectedHallPrice[0].selectedHallPrice = hallData[2];
        selectedHallInUnincharge[0].selectedHallInUnincharge = hallData[4];

        setnewinstallUnistallCharge(hallData[4]);

        setShowLoading(true);

        db.collection("hall").doc(hallData[0])
            .collection("booked_dates").get().then(function (querysnapshot) {




           /* state.push({
                showDateDisplay:false,
                color: '#13ff29',
            })
            setState(state);*/

            state = [{
                startDate: new Date(),
                endDate: new Date(),
                showDateDisplay:false,
                key: 'selection'
            }]

            querysnapshot.forEach(function (doc) {

                const hallData = doc.data();
                const hallStartDate = hallData.e_startDate.toDate();
                const hallEndDate = hallData.e_endDate.toDate();

                // alert(hallData);

                state.push({
                    startDate: new Date(hallStartDate),
                    endDate: new Date(hallEndDate),
                    key: 'selection',
                    showDateDisplay:false,
                    color: '#ff001d',
                })

            });

            setTimeout(function () {

                setState(state);//
                setShowLoading(false);

            },500)



        });






        hallSinglePrice = parseFloat(hallData[2]);
        hallTotalPrice = parseFloat(hallSinglePrice)+parseFloat(adServiceTotal);
        setSubTotalPrice(hallTotalPrice);
        newsetSelectedHallPrice(hallPrice[0].hallPrice)

       // document.getElementById("hall-price").innerHTML = "Hall Price : SR "+hallPrice[0].hallPrice;
      //  document.getElementById("total-price").innerHTML = "Total Price : SR "+hallTotalPrice;

    };

    const updateEventInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        eventName[0].eventName = event.target.value;
    };

    const updateInstallationDays = (event: React.ChangeEvent<HTMLInputElement>) => {


        if (numberOfDaysSelected==="0"){

            //alert('select date first')
            swal("Select Event Date First");

            numberOfInstallUninstallDays[0].numberOfInstallUninstallDays = ""


        }else {

            if(parseInt(event.target.value) < numberOfDaysSelected){

                numberOfInstallUninstallDays[0].numberOfInstallUninstallDays = event.target.value;
                setnumberOfInUnSelected(event.target.value)


            }else {

                swal("Installation Days lessthan Selected Dates");

            }




        }



    };


    const handleStartTimeChange = (time) => {

        setSelectedStartTime(time)
        var selTime = new Date(time);

        var hours = selTime.getHours();
        var seconds = selTime.getSeconds();
        var newHour  = (selTime.getHours() + 24) % 12 || 12;
        var minutes = selTime.getMinutes();


       // alert(formatAMPM(selTime));

       // console.log(formatAMPM(selTime));
        startTime[0].startTime = formatAMPM(selTime);

    };

    const handleEndTimeChange = (time) => {

        setSelectedEndTime(time)
        var selTime = new Date(time);

        var hours = selTime.getHours();
        var seconds = selTime.getSeconds();
        var newHour  = (selTime.getHours() + 24) % 12 || 12;
        var minutes = selTime.getMinutes();

        if(startTime[0].startTime > endTime[0].endTime){

            swal("INVALID TIME");
            startTime[0].startTime = "";
            endTime[0].endTime = "";

        }



        endTime[0].endTime = formatAMPM(selTime);

    };


    const updateFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {



        eventFiles[0].eventFiles = event.target.files[0];
       // eventFiles[0].eventFiles = event;
        console.log(event.target.files[0])
    };

    //updateFileSelection2
    const updateFileSelection2 = (event: React.ChangeEvent<HTMLInputElement>) => {



        layoutFiles[0].layoutFiles = event.target.files[0];
        console.log(event.target.files[0])
    };

    const updateEventDescription = (event: React.ChangeEvent<HTMLInputElement>) => {

        eventDescription[0].eventDescription = event.target.value;

    }

    function removeFromBlock(deleteId) {

       // alert(deleteId);
        hallBlocks.splice(hallBlocks.findIndex(v => v.lName === deleteId), 1);
       // alert('removed');
        handleClickOpen1();



    }

    function AddToBlocks() {

         var lName =  document.getElementById("standard-label-name-input").value;
         var lPrice = document.getElementById("standard-label-price-input").value;

        if(lName===""){
            swal("Booth Name Cannot Empty");

        }else if(lPrice==="") {

            swal("Price Cannot Empty");

        }else {

            createBlocks(lName,lPrice);

            hallBlocks.push(createBlocks(lName,lPrice))
            handleClose()

        }



    }

    const [booths,setBooth] = React.useState([]);
    const addToBooth = () => {


        setShowLoading(true);
        booths.push(createBooth(booths.length+1,"",""));

        setTimeout(()=>{
            setBooth(booths);
            setShowLoading(false);

        },500);



    };
    const removeFromBooth = (e) =>  {



        booths.splice(booths.findIndex(v => v.count === e),1);
        setShowLoading(true);
        setTimeout(()=>{
            setBooth(booths);
            setShowLoading(false);

        },500)





    };
    const updateHallBoothName = (props) => (e) => {


        const updateIndex =  booths.findIndex((v => v.count === props),1);


        booths[updateIndex].boothName = e.target.value;
        setShowLoading(true);

        setTimeout(()=>{

            setBooth(booths);
            setShowLoading(false);
        },0)

    };
    const updateHallBoothPrice = (props) => (e)  => {

        //  alert(e);
        const updateIndex =  booths.findIndex((v => v.count === props),1);
        booths[updateIndex].boothPrice = e.target.value;
        setShowLoading(true);

        setTimeout(()=>{

            setBooth(booths);
            setShowLoading(false);
        },0)

    };


    const addBooking = e => {

        e.preventDefault();

        /*if(checkBookingSlot(selectedHallId[0].selectedHallId,eStartDate,eEndDate)){

            alert('slot already booked')

        }else {

            alert('all set')
        }*/

        /*checkBookingSlot(selectedHallId[0].selectedHallId,eStartDate,eEndDate,function (callback) {

                     if(callback.status){

                         alert('AlreadyBooked')

                     }else {

                         alert('Available Slot')
                     }

        })*/





        if(organizerName===""){


            swal("Organizer Name Cannot Empty");

        }
        else if(eStartDate==="" || eEndDate ===""){

            swal("Please Select Date or already booked this date");


        }
        else if(selectedHallId[0].selectedHallId===""){


            swal("Please Select Hall");

        }
        else if(eventName[0].eventName===""){


            swal("Event Name Cannot Empty");

        }else if(startTime[0].startTime===""){


            swal("Please Select Start Time/Invalid Time Selected");

        }
        else if(endTime[0].endTime===""){


            swal("Please Select End Time/Invalid Time Selected");

        }else if (eventFiles[0].eventFiles===""){


            swal("Please Select Event Image");

        }else if (layoutFiles[0].layoutFiles===""){


            swal("Please Select Layout Image");

        }
        else if(eventDescription[0].eventDescription===""){


            swal("Event Description Cannot Empty");

        }else {

            if (validDateRange) {

                setShowLoading(true);

                var bannerImage  = eventFiles[0].eventFiles;
                var bannerFileName = (+new Date()) + '-' + bannerImage.name;

                var storageRef = firebase.storage().ref();
                var uploadTask = storageRef.child('eventImages/'+bannerFileName+'').put(bannerImage);
                uploadTask.on('state_changed', function(snapshot){

                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                    }
                }, function(error) {

                },function () {

                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){


                        var eventImageUrl = downloadURL.toString();
                        var eventFileName = bannerFileName;
                        // alert(downloadURL.toString());

                        var Difference_In_Time = new Date(eEndDate).getTime() - new Date(eStartDate).getTime();
                        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);



                        db.collection("booking").add({

                            "b_e_id":"",
                            "o_id":organizerId[0].organizerId,
                            "o_name":organizerName,
                            "o_c_name":organizerCName[0].organizerCName,
                            "b_e_description":eventDescription[0].eventDescription,
                            "h_e_hall_name":selectedHall[0].selectedHall,
                            "h_e_hall_id":selectedHallId[0].selectedHallId,
                            "b_e_hall_type":selectedHallType[0].selectedHallType,
                            "h_e_hall_price":selectedHallPrice[0].selectedHallPrice,
                            "h_e_hall_in_un_charge":selectedHallInUnincharge[0].selectedHallInUnincharge,
                            "b_e_licensedoc":"",
                            "b_e_name":eventName[0].eventName,
                            "b_e_no_invities":"",
                            "b_e_status":"new",
                            "b_e_type":"",
                            "b_e_type_id":"",
                            "is_live":true,
                            "from":startTime[0].startTime,
                            "to":endTime[0].endTime,
                            "e_startDate":eStartDate,
                            "e_endDate":eEndDate,
                            "hall_price":hallPrice[0].hallPrice,
                            "booking_date_count":parseInt(Difference_In_Days)+1,
                            "in_un_date_count":numberOfInUnSelected,
                            "total_price":subTotalPrice,
                            "current_time":new Date(),
                            "booking_dates":selectedDate

                        }).then(function (docRef) {

                            var docId = docRef.id;

                            var day1 = new Date(eStartDate).getDate();
                            var month1 = parseInt(new Date(eStartDate).getMonth())+1;
                            var year1 = new Date(eStartDate).getFullYear();

                            var day2 = new Date(eEndDate).getDate();
                            var month2 = parseInt(new Date(eEndDate).getMonth())+1;
                            var year2 = new Date(eEndDate).getFullYear();

                            var eventStartDate = day1+"/"+month1+"/"+year1;
                            var eventEndDates = day2+"/"+month2+"/"+year2;


                            db.collection("booking").doc(docId).update({

                                "b_e_id":docRef.id

                            });

                            db.collection("hall").doc(selectedHallId[0].selectedHallId).update({
                                "e_startDate": eStartDate,
                                "e_endDate":eEndDate

                            }).then(function() {

                            }).catch(function(error) {

                            });

                            const dId = Math.floor(Math.random()*90000) + 10000;

                            db.collection("hall").doc(selectedHallId[0].selectedHallId).collection("booked_dates").doc(dId.toString()).set({
                                "id":dId.toString(),
                                "from":startTime[0].startTime,
                                "to":endTime[0].endTime,
                                "booked_date":eventStartDate+"-" +eventEndDates,
                                "e_startDate": eStartDate,
                                "e_endDate":eEndDate

                            });

                            db.collection("booking").doc(docId).collection("booking_dates").doc(dId.toString()).set({
                                "id":dId.toString(),
                                "from":startTime[0].startTime,
                                "to":endTime[0].endTime,
                                "booked_date":eventStartDate+" " +eventEndDates,
                                "e_startDate": eStartDate,
                                "e_endDate":eEndDate

                            });



                            db.collection("booking").doc(docId).collection("event_images").add({

                                "e_image_id":"",
                                "e_image_filename":eventFileName,
                                "e_image_url":eventImageUrl

                            }).then(function (docRef3) {

                                var docId3 = docRef3.id;

                                db.collection("booking").doc(docId).collection("event_images").doc(docId3).update({

                                    "e_image_id":docId3,
                                })

                            });

                            selectedAdOnService.map(function (addonData) {

                                db.collection("booking").doc(docId).collection("ad_on_service").doc(addonData.label).set({

                                    "ad_s_id":addonData.label,
                                    "ad_s_type":addonData.value,
                                    "ad_s_amount":addonData.price

                                }).then(function (docRef4) {

                                })


                            });



                            if(booths.length>0){

                                booths.map(function (hData) {

                                    if(hData.boothName==="" || hData.boothPrice===""){

                                        // alert("Booth data cannot empty")
                                    }else {

                                        db.collection("booking").doc(docId).collection("event_blocks").add({

                                            "b_id":"",
                                            "b_label_price":hData.boothPrice,
                                            "b_lable_name":hData.boothName,
                                            "b_participent_id":"",
                                            "b_participent_name":"",
                                            "timeStamp":"",
                                            "b_participent_note":"",
                                            "b_participient_status":"",
                                            "o_status":""


                                        }).then(function (docRef4) {

                                            var docId4 = docRef4.id;
                                            db.collection("booking").doc(docId).collection("event_blocks").doc(docId4).update({

                                                "b_id":docId4,
                                            }).then(function (res) {

                                            });


                                        })




                                    }


                                });


                            }






                            var layoutImage  = layoutFiles[0].layoutFiles;
                            var layoutFileName = (+new Date()) + '-' + layoutImage.name;

                            var storageRef2 = firebase.storage().ref();
                            var uploadTask2 = storageRef2.child('layoutImages/'+layoutFileName+'').put(layoutImage);
                            uploadTask2.on('state_changed', function(snapshot){

                                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log('Upload is ' + progress + '% done');
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                }
                            }, function(error) {

                            },function () {

                                uploadTask2.snapshot.ref.getDownloadURL().then(function(downloadURL){

                                    var layoutImageUrl = downloadURL.toString();
                                    var layoutImageFileName = layoutFileName;

                                    db.collection("booking").doc(docId).collection("layout_image").add({

                                        "l_id":"",
                                        "l_image_filename":layoutImageFileName,
                                        "l_image_url":layoutImageUrl

                                    }).then(function (docRef5) {

                                        var docId5 = docRef5.id;

                                        db.collection("booking").doc(docId).collection("layout_image").doc(docId5).update({

                                            "l_id":docId5

                                        }).then(function (res) {

                                            setTimeout(function () {


                                                setShowLoading(false);
                                                swal("Successfully Booked").then((value) => {

                                                    window.location.reload();

                                                });
                                                //  swal("Please Select Event Image");
                                                // document.getElementById("booking-form").reset();


                                            },5000)





                                        });



                                    })




                                })

                            });

                        });




                    });



                });



            }else{


                swal("Date Already Booked")
            }





        }

    };


    React.useState(()=>{

        db.collection("organiser").doc(userId).get().then((doc) => {

            setOrganizers([]);
            if(doc.exists){

                var ids = doc.id;
                console.log(ids);
                const data = doc.data();
                var oId = data.o_id;
                var oName = data.o_name;
                var oCName = data.o_c_name;




                organiser.push(createOrganiser(oId,oName,oCName))

                setTimeout(()=>{

                    setOrganizers(organiser);
                   // organizerName[0].organizerName=oName;
                    setOrganizerName(oName);

                   // organizerName[0].organizerName = oName;
                    organizerId[0].organizerId = oId;
                    organizerCName[0].organizerCName = oCName;

                },500)


            }else{

            }


        });

        db.collection("hall").where("status","==","1").get().then((querySnapshot) => {

            setHalls([]);
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);

                var ids = doc.id;
                console.log(ids);



                const data = doc.data();
                var hId = data.h_id;
                var hName = data.h_name;
                var hPrice = data.h_price;
                var hType = data.h_type;
                var hInUnCharge = data.in_un_charge;

                halls.push(createHall(hId,hName,hPrice,hType,hInUnCharge))



            });

            setTimeout(()=>{

                setHalls(halls);
            },500)



        });

        db.collection("ad_on_service").get().then((querySnapshot) => {


            setAdonServices([]);
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);

                var ids = doc.id;
                console.log(ids);

                const data = doc.data();
                var adSId = data.ad_s_id;
                var adStype = data.ad_s_type;
                var adSAmount = data.ad_s_amount;

                adOnServices.push(createAdOnService(adSId,adStype,adSAmount))



            });

            setTimeout(()=>{

                setAdonServices(adOnServices)

            },500)



        });



    });


    var getDates = function(startDate, endDate) {
        var dates = [],
            currentDate = startDate,
            addDays = function(days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    };


    const handleDateRange = (props)=> {


        const startDate = props.startDate;
        const endDate = props.endDate;
        seteStartDate(startDate);
        seteEndDate(endDate);



        if(selectedHallId[0].selectedHallId === ""){


            swal("Select hall First");

        }else {


            setTimeout(()=>{


                var numOfDatesSelected = getDates(new Date(startDate), new Date(endDate));

                setnumberOfDaysSelected(numOfDatesSelected.length)


            },1000);

            if(new Date(startDate) <= new Date()){



                swal("Cannot select past Dates");


            }else {

                db.collection("hall").doc(selectedHallId[0].selectedHallId)
                    .collection("booked_dates")
                    .get()
                    .then(function (querySnapshot) {

                        querySnapshot.forEach(function (doc) {

                            var dateData = doc.data()

                            var sDate = dateData.e_startDate.toDate();
                            var eDate = dateData.e_endDate.toDate();



                            var aStart = new Date(sDate);
                            var aEnd = new Date(eDate);

                            var bStart = new Date(startDate);
                            var bEnd = new Date(endDate)

                            if (bStart >= aStart && bStart <= aEnd && bEnd >= aStart && bEnd <= aEnd) {

                                swal("already booked");
                                setValidDateRange(false)
                            }else {

                                if(bStart >= aStart && bStart <= aEnd){

                                    swal("already booked");
                                    setValidDateRange(false)
                                }else {


                                    setValidDateRange(true)


                                }


                            }








                        })




                    })

               /* db.collection("hall").doc(selectedHallId[0].selectedHallId)
                    .collection("booked_dates")
                    .where("e_startDate","==",new Date(startDate))
                    .where("e_startDate",">",new Date(startDate))
                    .get()
                    .then(function (querySnapshot) {

                       // seteStartDate("");


                        db.collection("hall").doc(selectedHallId[0].selectedHallId)
                            .collection("booked_dates")
                            .where("e_endDate","==",new Date(endDate)).where("e_endDate","<",new Date(endDate)).get().then(function (querySnapshot2) {

                           // seteStartDate("");

                            querySnapshot2.forEach(function (doc2) {

                                swal("already booked");
                               // seteStartDate("");//
                            });
                        });

                    })*/

            }






        }







    };



    return (

        <form className={classes.root} id="booking-form" noValidate autoComplete="off" onSubmit={addBooking}>
            <div>
                <div className={classes.root2} id="p-bar">
                    <CircularProgress />
                </div>

               {/* <div className={classes.root3}>
                    {showAlert ? <Alert  severity="success" autoHideDuration={2000}>Successfully Created</Alert>:null}

                </div>*/}

                <div className={classes.root3}>
                    {showAlert ? <Alert  severity="error" autoHideDuration={2000}>{alertMessage} </Alert>:null}

                </div>

                {showLoading===true ? <div className={classes.root4}>
                    <CircularProgress  />
                </div>:null}

                <FormGroup row>

                    <TextField
                        id="standard-event-name-input"
                        label={ selectedLanguage==='ar' ? "اسم الحدث":selectedLanguage==='en' ? "Organizer Name":null}
                        type="text"
                        name="eventName"
                        variant="outlined"
                        autoComplete="current-password"
                        onChange={updateEventInput}
                        value={organizerName}
                    />






                <TextField
                    id="standard-hall-input"
                    select
                    label={ selectedLanguage==='ar' ? "صالة":selectedLanguage==='en' ? "Hall":null}
                    name="hall"
                    value={selectedHall.selectedHall}
                    onChange={updateHallInput}
                    helperText=""
                    variant="outlined">
                    {halls.map((option) => (
                        <MenuItem key={option.value} value={option.value+'|'+option.label+'|'+option.price+'|'+option.type+'|'+option.hInUnCharge}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>





                  {/*  <Button variant="contained" style={{height:'55px',width:'180px',marginTop:'8px'}}>Hall Details</Button>*/}

                    {/*<Box mt={1}>
                        <Button variant="outlined" className={classes.mPicker}>Select Date
                            <MultipleDatePicker
                                open={true}
                                id="sel-date-picker"
                                onSubmit={dates => handleDateChange(dates)}

                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Button>
                    </Box>*/}

                    <TextField
                        id="standard-event-name-input"
                        label={ selectedLanguage==='ar' ? "اسم الحدث":selectedLanguage==='en' ? "Event Name":null}
                        type="text"
                        name="eventName"
                        variant="outlined"
                        autoComplete="current-password"
                        onChange={updateEventInput}
                        value={eventName.eventName}
                    />

                    <DateRangePicker
                        style={{height:'300px',width:'400px',maxHeight:'300px'}}
                        onChange={item => {setState([item.selection]);handleDateRange(item.selection)}}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"

                    />


                </FormGroup>
                <FormGroup row>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            margin="normal"
                            inputVariant="outlined"
                            id="time-picker"
                            label={ selectedLanguage==='ar' ? "وقت البدء":selectedLanguage==='en' ? "Start Time":null}
                            value={selectedStartTime}
                            onChange={handleStartTimeChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            margin="normal"
                            inputVariant="outlined"
                            id="time-picker"
                            label={ selectedLanguage==='ar' ? "وقت النهاية":selectedLanguage==='en' ? "End Time":null}
                            value={selectedEndTime}
                            onChange={handleEndTimeChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <TextField
                        id="doc-file"
                        label={ selectedLanguage==='ar' ? "صورة الحدث":selectedLanguage==='en' ? "Event Image":null}
                        variant="outlined"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={updateFileSelection}
                    />

                   {/* <Box mt={1.5}>
                        <Avatar style={Object.assign({}, avatarImageStyle, {color: 'grey' })} variant="square" src={eventFiles.eventFiles} />
                    </Box>*/}

                    <TextField
                        id="layout-image"
                        label={ selectedLanguage==='ar' ? "صورة التخطيط":selectedLanguage==='en' ? "Layout Image":null}
                        variant="outlined"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={updateFileSelection2}
                    />

                </FormGroup>
                <FormGroup row>

                    <TextField
                        id="standard-event-name-input"
                        label={ selectedLanguage==='ar' ? "عدد أيام التثبيت وإلغاء التثبيت":selectedLanguage==='en' ? "No of install and uninstall Days":null}
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="noOfUninstallationDays"
                        variant="outlined"
                        autoComplete="current-password"
                        onChange={updateInstallationDays}
                        value={numberOfInstallUninstallDays.numberOfInstallUninstallDays}
                    />

                </FormGroup>
                <FormGroup row>
                    <Box mt={2} ml={1} >
                        <TextareaAutosize variant="outlined" style={{width:'400px'}} onChange={updateEventDescription} aria-label="minimum height" rowsMin={6} placeholder={ selectedLanguage==='ar' ? "وصف":selectedLanguage==='en' ? "Description":null}/>
                    </Box>

                </FormGroup>
                <FormGroup row>
                    <Box mt={2} ml={1} >

                        <Typography variant="h6" gutterBottom>

                            { selectedLanguage==='ar' ? "خدمات الملحق":selectedLanguage==='en' ? "Addon Services":null}
                        </Typography>

                        {adOnServices.map((option) => (
                            <FormControlLabel
                                control={<Checkbox  onChange={handleChange} name="checkedA" value={option.label+"|"+option.value+"|"+option.price} />}
                                label={option.label}
                            />
                        ))}



                    </Box>

                </FormGroup>




            </div>
            <div>



                <FormGroup row>
                    <Box mt={5} ml={1} width={800}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table" id="block-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>

                                        { selectedLanguage==='ar' ? "اسم الطابع":selectedLanguage==='en' ? "Booth Name":null}
                                    </TableCell>
                                    <TableCell>

                                        { selectedLanguage==='ar' ? "سعر الملصق":selectedLanguage==='en' ? "Price":null}
                                    </TableCell>
                                    <TableCell>
                                        {/*<Box mt={0} ml={1}>
                                            <Fab size="small" onClick={handleClickOpen('paper')} color="primary" aria-label="add">
                                                <AddIcon />
                                            </Fab>
                                        </Box>*/}
                                        <Box mt={0} ml={1}>
                                            <Fab size="small" onClick={addToBooth} style={{maxHeight:'10px',maxWidth:'35px'}}    color="primary" aria-label="add">
                                                <AddIcon />
                                            </Fab>

                                        </Box>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    booths.map((row) => (

                                        <TableRow key={row.count}>
                                            <TableCell>
                                                <TextField
                                                    id="standard-label-name-input"
                                                    label={ selectedLanguage==='ar' ? "اسم المقصورة":selectedLanguage==='en' ? "Booth Name":null}

                                                    type="text"
                                                    name="labelName"
                                                    variant="outlined"
                                                    autoComplete="current-password"
                                                    value={row.boothName}
                                                    onChange={updateHallBoothName(row.count)}
                                                />
                                            </TableCell>
                                            <TableCell><TextField
                                                id="standard-label-name-input"
                                                label={ selectedLanguage==='ar' ? "كشك الأسعار":selectedLanguage==='en' ? "Booth Price":null}
                                                type="number"
                                                name="labelName"
                                                variant="outlined"
                                                autoComplete="current-password"
                                                value={row.boothPrice}
                                                onChange={updateHallBoothPrice(row.count)}
                                            /></TableCell>
                                            <TableCell><IconButton aria-label="delete" onClick={() => removeFromBooth(row.count)}  ><DeleteIcon fontSize="small"  /></IconButton></TableCell>
                                        </TableRow>
                                    ))
                                }



                              {/*  {

                                    hallBlocks.map((row) => (



                                    <TableRow key={row.lName}>
                                        <TableCell>
                                            {row.lName}
                                        </TableCell>
                                        <TableCell>{row.lPrice}</TableCell>
                                        <TableCell><IconButton aria-label="delete" onClick={() => removeFromBlock(row.lName)}  ><DeleteIcon fontSize="small"  /></IconButton></TableCell>
                                    </TableRow>
                                ))

                                }*/}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Box>

                </FormGroup>


                <Box mt={2} ml={1} width={500}>
                    <Card  variant="outlined">
                        <CardContent>

                            <Typography   variant="h6" gutterBottom>
                                { selectedLanguage==='ar' ? "تفاصيل السعر":selectedLanguage==='en' ? "Price details":null}
                            </Typography>

                            <Table  size="small" aria-label="a dense table" style={{backgroundColor:'#e6dbff',width:'400px'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> { selectedLanguage==='ar' ? "سعر القاعة:":selectedLanguage==='en' ? "Hall Price (SR)":null}</TableCell><TableCell>: {parseFloat(newselectedHallPrice).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{ selectedLanguage==='ar' ? "تثبيت وإلغاء الشحن:":selectedLanguage==='en' ? "Install And Uninstall Charge (SR)":null}</TableCell><TableCell>: {newinstallUnistallCharge}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{ selectedLanguage==='ar' ? "عدد الأيام:":selectedLanguage==='en' ? "Number of Days":null} </TableCell><TableCell>: {numberOfDaysSelected}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{ selectedLanguage==='ar' ? "عدد أيام التثبيت / إلغاء التثبيت:":selectedLanguage==='en' ? "Number of install/Uninstall Days":null} </TableCell><TableCell>: {numberOfInUnSelected}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{ selectedLanguage==='ar' ? "السعر الكلي:":selectedLanguage==='en' ? "Total Price (SR)":null}</TableCell><TableCell>: {parseFloat(parseFloat((parseInt(numberOfDaysSelected)*parseFloat(newselectedHallPrice)).toFixed(2))-parseFloat((parseInt(numberOfInUnSelected)*parseFloat(newselectedHallPrice)).toFixed(2))).toFixed(2)}</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{ selectedLanguage==='ar' ? "إجمالي سعر التثبيت / إلغاء التثبيت:":selectedLanguage==='en' ? "Total Install/Uninstall Price (SR)":null}</TableCell><TableCell>: {(parseInt(numberOfInUnSelected)*parseFloat(newinstallUnistallCharge)).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><p id="ads-service-price">

                                            { selectedLanguage==='ar' ? "خدمة الملحق:":selectedLanguage==='en' ? "Addon service :":null}
                                        </p></TableCell><TableCell>: {(parseFloat(totalAddonServiceAmount)*parseInt(numberOfDaysSelected)).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            { selectedLanguage==='ar' ? "المجموع الفرعي:":selectedLanguage==='en' ? "SubTotal (SR)":null}
                                        </TableCell>
                                        <TableCell>: {parseFloat(parseFloat((parseInt(numberOfDaysSelected)*parseFloat(newselectedHallPrice)).toFixed(2))-parseFloat((parseInt(numberOfInUnSelected)*parseFloat(newselectedHallPrice)).toFixed(2))+parseFloat((parseFloat(totalAddonServiceAmount)*parseInt(numberOfDaysSelected)).toFixed(2))+parseFloat((parseInt(numberOfInUnSelected)*parseFloat(newinstallUnistallCharge)).toFixed(2))).toFixed(2)}</TableCell>
                                    </TableRow>

                                </TableHead>
                            </Table>





                           {/* <Typography  color="textSecondary">

                                { selectedLanguage==='ar' ? "تفاصيل السعر":selectedLanguage==='en' ? "Price details":null}
                            </Typography>
                            <Typography  color="textSecondary">

                                <FormGroup row>
                                    <p id="hall-price">

                                        { selectedLanguage==='ar' ? "سعر القاعة:":selectedLanguage==='en' ? "Hall Price :":null}
                                    </p>
                                </FormGroup>
                            </Typography>
                            <Typography  color="textSecondary">
                                <p id="ads-service-price">

                                    { selectedLanguage==='ar' ? "أضف على الخدمة:":selectedLanguage==='en' ? "Add on service :":null}
                                </p>
                            </Typography>
                            <Typography  color="textSecondary">
                                <p id="total-price">

                                    { selectedLanguage==='ar' ? "السعر الكلي :":selectedLanguage==='en' ? "Total Price :":null}
                                </p>
                            </Typography>*/}


                        </CardContent>

                    </Card>
                </Box>





            </div>
            <div>
                <Box mt={3} ml={1}>
                <Button    type="submit"  variant="contained" color="primary">

                    { selectedLanguage==='ar' ? "احجز الآن":selectedLanguage==='en' ? "Book":null}
                </Button>
                </Box>
            </div>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">

                        { selectedLanguage==='ar' ? "إضافة كتل القاعة":selectedLanguage==='en' ? "Add Hall Blocks":null}
                    </DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <FormGroup row>
                                <TextField
                                    id="standard-label-name-input"
                                    label={ selectedLanguage==='ar' ? "اسم الطابع":selectedLanguage==='en' ? "Label Name":null}
                                    type="text"
                                    name="labelName"
                                    variant="outlined"
                                    autoComplete="current-password"

                                />
                                <TextField
                                    id="standard-label-price-input"
                                    label={ selectedLanguage==='ar' ? "سعر الملصق":selectedLanguage==='en' ? "Label Price":null}
                                    type="text"
                                    name="labelPrice"
                                    variant="outlined"
                                    autoComplete="current-password"
                                />

                            </FormGroup>


                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={AddToBlocks} color="primary">

                            { selectedLanguage==='ar' ? "حفظ":selectedLanguage==='en' ? "Save":null}
                        </Button>
                        <Button onClick={handleClose} color="primary">

                            { selectedLanguage==='ar' ? "قريب":selectedLanguage==='en' ? "Close":null}
                        </Button>
                    </DialogActions>
                </Dialog>


            </div>

            <div>
                <Dialog
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           Successfully Deleted
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose1} color="primary">
                            OK
                        </Button>
                      {/*  <Button onClick={handleClose1} color="primary" autoFocus>
                            Agree
                        </Button>*/}
                    </DialogActions>
                </Dialog>
            </div>








        </form>








    );
}