import React from 'react';
import TextField from '@material-ui/core/TextField';
import {createStyles, makeStyles, withStyles, Theme} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {
    BrowserRouter as Router,
    Switch, Route,Link
} from "react-router-dom";

const db = firebase.firestore();
const storage = firebase.storage();


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },

    }),


);

function createHall(hId, hName, hCapacity, hDescription, hType, hTotalArea, hPrice) {

    return {hId, hName, hCapacity, hDescription, hType, hTotalArea, hPrice}
}

const rows = [];


var hallId = [{hallId: ""}];
var hallName = [{hallName: ""}];

db.collection("hall").get().then((querySnapshot) => {

    var hallCount = 0;
    querySnapshot.forEach((doc) => {

        hallCount++;
        const ids = doc.id;

        const hallData = doc.data();
        const hId = hallData.h_id;
        const hName = hallData.h_name;
        const hCapacity = hallData.h_capacity;
        const hDescription = hallData.h_desciptions;
        const hType = hallData.h_type;
        const hTotalArea = hallData.h_total_area;
        const hPrice = hallData.h_price;


        rows.push(createHall(hId, hName, hCapacity, hDescription, hType, hTotalArea, hPrice))


    });

});


export default function Hall() {
    const classes = useStyles();


    return (
        <div>
            <Link to="/createHall" className={classes.link}>
                <Button aria-controls="simple-menu" id="b-status-button" variant="contained" color="primary"
                        aria-haspopup="true">
                    Create
                </Button>
            </Link>
                <Box mt={2}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="left">Capacity</StyledTableCell>
                                <StyledTableCell align="left">Description</StyledTableCell>
                                <StyledTableCell align="left">Type</StyledTableCell>
                                <StyledTableCell align="left">Total Area</StyledTableCell>
                                <StyledTableCell align="left">Price</StyledTableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.hId} component={Link} className={classes.link} to={'/hallDetails/'+row.hId}>
                                    <StyledTableCell align="left">{row.hName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.hCapacity}</StyledTableCell>
                                    <StyledTableCell align="left">{row.hDescription}</StyledTableCell>
                                    <StyledTableCell align="left">{row.hType}</StyledTableCell>
                                    <StyledTableCell align="left">{row.hTotalArea}</StyledTableCell>
                                    <StyledTableCell align="left">{row.hPrice}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Box>
               </div>
    )

}