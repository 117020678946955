import React from 'react';
//import { withStyles, makeStyles } from '@material-ui/core/styles';
import { makeStyles,withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';


import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";

import firebase from '../services/firebase'




const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);



const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(oCompanyName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType,address,oArea,oCity,expDate,loginMail,loginPassword,crDocument) {
    return { oCompanyName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType,address,oArea,oCity,expDate,loginMail,loginPassword,crDocument };
}

function createBookingData(bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,bookingDate) {
    return { bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,bookingDate };
}



var rows = [];
var rows2 = [];
const db = firebase.firestore();

function getOrganiserData(organiserId) {

    db.collection("organiser").doc(organiserId).get().then((doc) => {

        rows = [];

        if (doc.exists) {

            var ids = doc.id;
            console.log(ids);

            const data = doc.data();
            var oId = data.o_id;
            var oName = data.o_name;
            var cName = data.o_c_name;
            var oEmail = data.o_email;
            var oGender = data.o_gender;
            var phone = data.o_mobilenumber;
            var vatNumber = data.o_vat_number;
            var oCrNumber = data.o_cr_number;
            var oBussinessType = data.o_type;
            var oAddress = data.o_address;
            var line1 = oAddress.line1;
            var line2 = oAddress.line2;
            var line3 = oAddress.line3;
            var oArea = data.o_area;
            var oCity = data.o_city;
            var expDate = data.o_cr_expiry_date;
            var loginMail = data.o_login_mail;
            var loginPassword = data.o_login_password;
            var crDocument = data.o_cr_document;

            var totalAddress = line1+","+line2+","+line3;

            rows.push(createData(cName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType,totalAddress,oArea,oCity,expDate,loginMail,loginPassword,crDocument))


        }




       /* querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);


        });*/

    });


}

function getOrganizerBookings(organiserId) {



    db.collection("booking").where("o_id", "==", organiserId).get().then(function (querySnapshot) {

                  var bookingCount = 0;
                  rows2=[];
                  querySnapshot.forEach(function (doc) {

                      bookingCount++;
                      const data = doc.data();
                      var beId = data.b_e_id;
                      var beName = data.b_e_name;
                      var beStatus = data.b_e_status;
                      var beType = data.b_e_type;
                      var organizationName = data.o_c_name;
                      var oName = data.o_name;
                      var hallType = data.b_e_hall_type;
                      var isLive = data.is_live;


                     // rows2.push(createBookingData(bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,""))



                      getBookingDate(beId,bookingCount,function (callBack) {

                          rows2 = [];
                          rows2.push(createBookingData(bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,callBack.bookingDate))





                      });


                  });


    }).catch(function (error) {

        console.log("Error getting documents: ", error);

    });



}

function getBookingDate(beId,bCount,callback) {


    db.collection("booking").doc(beId).collection("booking_dates").get().then(function (querySnapshot) {

        var callBackString = {};
        querySnapshot.forEach(function (doc) {


            var bDate = doc.data();
            callBackString.bookingDate = bDate.booked_date;

        });
        callback(callBackString);



    });


}



const useStyles = makeStyles((theme: Theme) => {

    createStyles({

        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary
        },
        link2:{
            textDecoration:'none',
            color:theme.palette.text.primary
        }

    });


});

var orgId = "";
var orgStatus = "";





export default function OrganizerDetails() {

    let  {id}  = useParams();
    orgId = useParams().id;
    orgStatus = useParams().status;

    const classes = useStyles();
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const [state,setState] = React.useState({

        cName:"",
        oName:"",
        oEmail:"",
        oGender:"",
        phone:"",
        vatNumber:"",
        oCrNumber:"",
        oBussinessType:"",
        totalAddress:"",
        oArea:"",
        oCity:"",
        expDate:"",
        loginMail:"",
        loginPassword:"",
        crDocument:""


    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };




    // getOrganiserData(orgId);
    // getOrganizerBookings(orgId);

    db.collection("organiser").doc(orgId).get().then((doc) => {

        rows = [];

        if (doc.exists) {

            var ids = doc.id;
            console.log(ids);

            const data = doc.data();
            var oId = data.o_id;
            var oName = data.o_name;
            var cName = data.o_c_name;
            var oEmail = data.o_email;
            var oGender = data.o_gender;
            var phone = data.o_mobilenumber;
            var vatNumber = data.o_vat_number;
            var oCrNumber = data.o_cr_number;
            var oBussinessType = data.o_type;
            var oAddress = data.o_address;
            var line1 = oAddress.line1;
            var line2 = oAddress.line2;
            var line3 = oAddress.line3;
            var oArea = data.o_area;
            var oCity = data.o_city;
            var expDate = data.o_cr_expiry_date;
            var loginMail = data.o_login_mail;
            var loginPassword = data.o_login_password;
            var crDocument = data.o_cr_document;

            var totalAddress = line1+","+line2+","+line3;

            rows.push(createData(cName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType,totalAddress,oArea,oCity,expDate,loginMail,loginPassword,crDocument))

            setState({cName:cName,oName:oName,oEmail:oEmail,oGender:oGender,phone:phone,vatNumber:vatNumber,oCrNumber:oCrNumber,oBussinessType:oBussinessType,totalAddress:totalAddress,oArea:oArea,oCity:oCity,expDate:expDate,loginMail:loginMail,loginPassword:loginPassword,crDocument:crDocument})


           /* setState({
                bodyData
            });*/

        }






    });

   // getOrganizerBookings(orgId);


    db.collection("booking").where("o_id", "==", orgId).get().then(function (querySnapshot) {

        var bookingCount = 0;
        rows2=[];
        querySnapshot.forEach(function (doc) {

            bookingCount++;
            const data = doc.data();
            var beId = data.b_e_id;
            var beName = data.b_e_name;
            var beStatus = data.b_e_status;
            var beType = data.b_e_type;
            var organizationName = data.o_c_name;
            var oName = data.o_name;
            var hallType = data.b_e_hall_type;
            var isLive = data.is_live;
            var bookingDates = "";


            // rows2.push(createBookingData(bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,""))
            db.collection("booking").doc(beId).collection("booking_dates").get().then(function (querySnapshot) {

                var dateCount = 0;
                querySnapshot.forEach(function (doc) {

                    if(dateCount<2){

                        var bDate = doc.data();
                        var bbDate = bDate.booked_date;

                        bookingDates+= bbDate;

                    }



                });



            });

            rows2.push(createBookingData(bookingCount,beId,beName,beStatus,hallType,isLive,oName,organizationName,bookingDates))






        });


    }).catch(function (error) {

        console.log("Error getting documents: ", error);

    });





    return (<div>
            <Box mt={2}  >
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">CompanyName</StyledTableCell>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="left">Phone</StyledTableCell>
                                <StyledTableCell align="left">Email</StyledTableCell>
                                <StyledTableCell align="left">Gender</StyledTableCell>
                                <StyledTableCell align="left">Business Type</StyledTableCell>
                                <StyledTableCell align="left">CR Number</StyledTableCell>
                                <StyledTableCell align="left">VAT Number</StyledTableCell>
                                <StyledTableCell align="left">Area</StyledTableCell>
                                <StyledTableCell align="left">City</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <StyledTableRow>
                                <StyledTableCell align="left">{state.cName}</StyledTableCell>
                                <StyledTableCell align="left">{state.oName}</StyledTableCell>
                                <StyledTableCell align="left">{state.phone}</StyledTableCell>
                                <StyledTableCell align="left">{state.oEmail}</StyledTableCell>
                                <StyledTableCell align="left">{state.oGender}</StyledTableCell>
                                <StyledTableCell align="left">{state.oBussinessType}</StyledTableCell>
                                <StyledTableCell align="left">{state.oCrNumber}</StyledTableCell>
                                <StyledTableCell align="left">{state.vatNumber}</StyledTableCell>
                                <StyledTableCell align="left">{state.oArea}</StyledTableCell>
                                <StyledTableCell align="left">{state.oCity}</StyledTableCell>
                            </StyledTableRow>



                            {/*{rows.map((row) => (

                                <StyledTableRow key={row.oId}>
                                    <StyledTableCell align="left">{row.oCompanyName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.phone}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oEmail}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oGender}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oBussinessType}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oCrNumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.vatNumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oArea}</StyledTableCell>
                                    <StyledTableCell align="left">{row.oCity}</StyledTableCell>
                                </StyledTableRow>
                            ))}*/}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box mt={2}  >
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Address</TableCell>
                                <TableCell align="left">Expire Date</TableCell>
                                <TableCell align="left">Login Mail</TableCell>
                                <TableCell align="left">Password</TableCell>
                                <TableCell align="left">CR Document</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <StyledTableRow>
                                <TableCell align="left">{state.totalAddress}</TableCell>
                                <TableCell align="left">{state.expDate}</TableCell>
                                <TableCell align="left">{state.loginMail}</TableCell>
                                <TableCell align="left">
                                    <Input
                                        disabled
                                        id="standard-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={state.loginPassword}
                                        onChange={handleChange('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </TableCell>
                                <TableCell align="left">

                                    <a href={state.crDocument} target="_blank" style={{ textDecoration:'none'}}><Button variant="contained">View</Button></a>



                                </TableCell>
                            </StyledTableRow>



                           {/* {rows.map((row) => (

                                <StyledTableRow key={row.oId}>
                                    <TableCell align="left">{row.address}</TableCell>
                                    <TableCell align="left">{row.expDate}</TableCell>
                                    <TableCell align="left">{row.loginMail}</TableCell>
                                    <TableCell align="left">
                                        <Input
                                            disabled
                                            id="standard-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={row.loginPassword}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                   </TableCell>
                                    <TableCell align="left">

                                            <a href={row.crDocument} target="_blank" style={{ textDecoration:'none'}}><Button variant="contained">View</Button></a>



                                    </TableCell>
                                </StyledTableRow>
                            ))}*/}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

            <Box mt={2}  >

                <Typography color="textSecondary">
                    Bookings
                </Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>

                                <TableCell>SI.No</TableCell>
                                <TableCell>Booking Date</TableCell>
                                <TableCell>Event Name</TableCell>
                                <TableCell>Hall Type</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows2.map((row) => (

                                <TableRow key={row.beId} component={Link} style={{ textDecoration:'none'}} to={'/organizerBookingDetails/'+row.beId+'/'+orgId}>

                                    <TableCell>{row.bookingCount}</TableCell>
                                    <TableCell>{row.bookingDate}</TableCell>
                                    <TableCell>{row.beName}</TableCell>
                                    <TableCell>{row.hallType}</TableCell>


                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>

        </div>
    );
}
