import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '60ch',
            },
        },
        root2: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            paddingLeft:'30%',
            paddingTop:'15%',
            zIndex:10,
            position: 'fixed',
            visibility:'hidden'

        },
    }),
);

const db = firebase.firestore();
const storage = firebase.storage();





export default function Notification() {
    const classes = useStyles();

    const [showAlert,setAlert] = React.useState(false);
    const [data, setData] = useState([{ userType: ''}]);


    const updateUserType = (event) => {


        const userData = event.target.value.split("|");
        const userId = userData[0];
        const userName = userData[1];

      //  alert(userName);
        setData([{userType:userName}]);



    };


    const sendNotification = e => {

        e.preventDefault();
        setAlert(true);


        setTimeout(()=>{

            setAlert(false)

        },3000)



    };







    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={sendNotification}>
            <div>
                <div className={classes.root2} id="p-bar">
                    <CircularProgress />
                </div>

                <div>
                    {showAlert ? <Alert  severity="success" autoHideDuration={2000}>Successfully Send Notification</Alert>:null}

                </div>
                <Box mt={2}>
                <FormGroup row>
                <TextField
                    id="select-use-input"
                    select
                    label="Select User Type"
                    value={data.userType}
                    name="organizer"
                    onChange={updateUserType}
                    helperText=""
                    variant="outlined">
                    <MenuItem    value={"0"+"|"+"Participant"}>
                         Participant
                    </MenuItem>
                    <MenuItem   value={"1"+"|"+"Visitor"}>
                         Visitor
                    </MenuItem>
                </TextField>
                </FormGroup>
                <TextField
                    required
                    id="notification-input"
                    label="Notification Title"
                    type="text"
                    name="notification-title"
                    variant="outlined"
                    autoComplete="current-password"
                    fullWidth={true}
                />
                <FormGroup row>
                <TextField
                    id="outlined-multiline-static"
                    label="Notification Message"
                    multiline
                    rows={4}

                    variant="outlined"
                />
                </FormGroup></Box>


            </div>

            <div>
                <Box mt={3} ml={1}>
                    <Button type="submit" variant="contained" color="primary">
                        SEND
                    </Button>
                </Box>
            </div>
        </form>
    );
}