import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        root2: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            paddingLeft:'30%',
            paddingTop:'15%',
            zIndex:10,
            position: 'fixed',
            visibility:'hidden'

        },
    }),
);

const db = firebase.firestore();
const storage = firebase.storage();

function createFeature(fName,fPrice) {

    return{fName:fName,fPrice:fPrice}
}

const hallFeatures = [];



var hallName = [{ hallName:""}];
var hallPrice = [{ hallPrice:""}];
var hallArea = [{ hallArea:""}];
var hallType = [{ hallType:""}];
var hallCapacity = [{ hallCapacity:""}];
var hallDescription = [{ hallDescription:""}];
var hallFiles = [{ hallFiles:"" }];


export default function CreateHall() {
    const classes = useStyles();
    const [hallTypes,setHallTypes] = React.useState('Single');

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');


    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    function AddToFeatures() {

        var fName =  document.getElementById("standard-label-name-input").value;
      //  var fPrice = document.getElementById("standard-label-price-input").value;



        createFeature(fName,"");

        hallFeatures.push(createFeature(fName,""));
        handleClose()


    }

    function removeFromFeature(deleteId) {

        // alert(deleteId);
        hallFeatures.splice(hallFeatures.findIndex(v => v.fName === deleteId), 1);
        handleClickOpen1();



    }


    const updateNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        hallName[0].hallName = event.target.value;

    };
    const updateHallPriceInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        hallPrice[0].hallPrice = event.target.value;
    };
    const updateHallAreaInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        hallArea[0].hallArea = event.target.value;
    };
    const updateHallTypeInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        setHallTypes(event.target.value);
        hallType[0].hallType = event.target.value;

    };
    const updateHallCapacityInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        hallCapacity[0].hallCapacity = event.target.value;

    };
    const updateHallDescriptionInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        hallDescription[0].hallDescription = event.target.value;

    };


    const updateFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {



        hallFiles[0].hallFiles = event.target.files[0];

    };



    const addHall = e => {

        e.preventDefault();

        if(hallName[0].hallName===""){

             alert('Hall Name Required')


        }
        else if(hallFiles[0].hallFiles===""){

            alert('Hall Image Required')

        }else if(hallPrice[0].hallPrice===""){

            alert('Hall Price Required')

        }else if(hallArea[0].hallArea===""){

            alert('Hall Area Required')

        }else if(hallType[0].hallType===""){

            alert('Hall Type Required')

        }else if(hallCapacity[0].hallCapacity===""){

            alert('Hall Capacity Required')

        }else if(hallDescription[0].hallDescription===""){

            alert('Hall Description Required')

        }else {

            document.getElementById("p-bar").style.visibility = 'visible';

            var bannerImage  = hallFiles[0].hallFiles;
            var bannerFileName = (+new Date()) + '-' + bannerImage.name;

            var storageRef = firebase.storage().ref();
            var uploadTask = storageRef.child('hallImages/'+bannerFileName+'').put(bannerImage);
            uploadTask.on('state_changed', function(snapshot){

                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function(error) {

            }, function() {

                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {

                    db.collection("hall").add({

                        "h_id":"",
                        "h_name":hallName[0].hallName,
                        "h_price":hallPrice[0].hallPrice,
                        "h_total_area":hallArea[0].hallArea,
                        "h_type":hallType[0].hallType,
                        "h_desciptions":hallDescription[0].hallDescription,
                        "h_capacity":hallCapacity[0].hallCapacity



                    }).then(function (docRef) {

                        var docId = docRef.id;

                        db.collection("hall").doc(docId).update({

                            "h_id":docRef.id

                        }).then(function () {


                            db.collection("hall").doc(docId).collection("hall_images").add({

                                "image_id":"",
                                "image_url":downloadURL.toString(),
                                "image_file_name":bannerFileName

                            }).then(function (docRef2) {

                                var docId2 = docRef2.id;

                                db.collection("hall").doc(docId).collection("hall_images").doc(docId2).update({

                                    "image_id":docId2
                                }).then(function () {


                                    if(hallFeatures.length>0){


                                        hallFeatures.map(function (hData) {

                                            // alert(hData.lName);

                                            db.collection("hall").doc(docId).collection("hall_features").add({

                                                "features_id":"",
                                                "features_name":hData.fName


                                            }).then(function (docRef4) {

                                                var docId4 = docRef4.id;
                                                db.collection("hall").doc(docId).collection("hall_features").doc(docId4).update({

                                                    "features_id":docId4,
                                                }).then(function (res) {


                                                });


                                            })




                                        })

                                    }


                                    setTimeout(function () {

                                        document.getElementById("p-bar").style.visibility = 'hidden';
                                        document.getElementById("hall-form").reset();

                                    },3000)




                                })



                            });





                        });






                    });




                });
            });




        }








    };



    return (
        <form className={classes.root} id="hall-form" noValidate autoComplete="off" onSubmit={addHall}>
            <div>
                <div className={classes.root2} id="p-bar">
                    <CircularProgress />
                </div>
                <TextField
                    required
                    id="hall-name-input"
                    label="Hall Name"
                    type="text"
                    name="hallName"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateNameInput}
                    value={hallName.hallName}
                />
                <TextField
                    id="hall-price-input"
                    label="Price"
                    type="text"
                    name="hallPrice"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateHallPriceInput}
                    value={hallPrice.hallPrice}
                />
                <TextField
                    id="hall-area-input"
                    label="Hall Area"
                    type="text"
                    name="hallArea"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateHallAreaInput}
                    value={hallArea.hallArea}
                />

                <TextField
                    id="standard-hall-Type-input"
                    select
                    label="Hall type"
                    name="hallType"
                    value={hallTypes}
                    onChange={updateHallTypeInput}
                    helperText=""
                    variant="outlined">
                    {[{
                        value: 'Single',
                        label: 'Single',
                    },
                        {
                            value: 'Block',
                            label: 'Block',
                        },].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    id="standard-hallCapacity-input"
                    label="Capacity"
                    type="text"
                    name="hCapacity"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateHallCapacityInput}
                    value={hallCapacity.hallCapacity}
                />

            </div>
            <div>
                <FormGroup row>

                <Box mt={2} ml={1} >
                    <TextareaAutosize variant="outlined" onChange={updateHallDescriptionInput}   aria-label="minimum height" rowsMin={5} placeholder="Description"/>
                </Box>
                <Box mt={3} ml={1}>
                    <Button    onClick={handleClickOpen('paper')}   variant="contained" color="primary">ADD BLOCKS</Button>
                </Box>
                </FormGroup>

                <FormGroup row>
                    <Box mt={5} ml={1} width={300}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table" id="feature-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Feature Name</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {

                                        hallFeatures.map((row) => (



                                            <TableRow key={row.fName}>
                                                <TableCell>
                                                    {row.fName}
                                                </TableCell>
                                                <TableCell><IconButton aria-label="delete" onClick={() => removeFromFeature(row.fName)}  ><DeleteIcon fontSize="small"  /></IconButton></TableCell>
                                            </TableRow>
                                        ))

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                </FormGroup>

            </div>
            <div>

            </div>
            <div>

                <TextField
                    id="doc-file"
                    label="Hall Image"
                    variant="outlined"
                    type="file"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={updateFileSelection}
                />





            </div>
            <div>
                <Box mt={3} ml={1}>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </Box>
            </div>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Add Hall Features</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <FormGroup row>
                                <TextField
                                    id="standard-label-name-input"
                                    label="Hall Feature"
                                    type="text"
                                    name="hallFeature"
                                    variant="outlined"
                                    autoComplete="current-password"

                                />
                                {/*<TextField
                                    id="standard-label-price-input"
                                    label="Label Price"
                                    type="text"
                                    name="labelPrice"
                                    variant="outlined"
                                    autoComplete="current-password"
                                />*/}

                            </FormGroup>


                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={AddToFeatures} color="primary">
                            Save
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>


            </div>

            <div>
                <Dialog
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Successfully Deleted
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose1} color="primary">
                            OK
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>



        </form>
    );
}