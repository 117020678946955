import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import swal from 'sweetalert';





function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '32ch',
            },
        },
        root2: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            paddingLeft:'30%',
            paddingTop:'15%',
            zIndex:10,
            position: 'fixed',
            visibility:'hidden'

        },
        root3:{
            width: '45ch',
            float:'right'
        },
        textFieldBg:{
            background:'white',
            color:'black',
        },
        input: {
            display: 'none',
        },
        root4: {
            position:'fixed',
            display: 'flex',
            justifyContent:'center',
            marginTop:'60px',
            marginLeft:'40%',
            zIndex:1,
        },

    }),
);

const db = firebase.firestore();
const storage = firebase.storage();


var userIds = "";
var usernames = "";
var passwords = "";





export default function Profile() {
    const classes = useStyles();

    const [showAlert,setAlert] = React.useState(false);

    const userId = localStorage.getItem("userId") || '';
    const userName = localStorage.getItem("userName") || '';
    const selectedLanguage = localStorage.getItem("lang") || 'en';

    const [showLoading,setShowLoading] = React.useState(false);

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const [orgData,setOrgData] = React.useState({
        username:"",
        password:"",
        organizerName:"",
        organizationName:"",
        organizerAddress1:"",
        organizerAddress2:"",
        organizerAddress3:"",
        organizerArea:"",
        organizerCr:"",
        organizerMobileNumber:"",
        organizerStreet:"",
        organizerType:"",
        organizerVatNo:""

    });

    const [crDocData,setCrDocData] = React.useState({

        oldFileName:"",
        newFileName:"",
        oldFileUrl:"",

    })

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    const handleChange = (event) => {

        passwords = event.target.value;
        setOrgData({username:orgData.username,password:event.target.value})

    };





    const sendUpdateData = e => {

        e.preventDefault();

        //  alert(orgData.password);
        db.collection("organiser").doc(userId).update({

            password:orgData.password,
            o_login_password:orgData.password
        }).then(()=>{

            setAlert(true);

            setTimeout(()=>{

                setAlert(false)
                window.location.reload();
            },3000)

        });







    };

    const uploadCr = () => (event) => {

        var fileData = event.target.files[0];



        swal({
            title: "Are you sure?",
            text: "Sure Upload Cr Document",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                  //  const file = document.getElementById("contained-button-file").files[0];
                   // const fileName = document.getElementById("contained-button-file").files[0].name;




                    if(crDocData.oldFileName===""){

                        setShowLoading(true);

                        var bannerImage  = fileData;
                        var bannerFileName = (+new Date()) + '-' + bannerImage.name;

                        var storageRef = firebase.storage().ref();
                        var uploadTask = storageRef.child('organiserImages/'+bannerFileName+'').put(bannerImage);
                        uploadTask.on('state_changed', function(snapshot){

                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        }, function(error) {

                        }, function () {

                            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {


                                db.collection("organiser").doc(userId).update({

                                    "o_cr_document":downloadURL.toString(),
                                    "o_cr_document_file":bannerFileName
                                }).then(function () {

                                    swal("Successfully Uploadoed", {
                                        icon: "success",
                                    }).then(()=>{

                                        setShowLoading(false);
                                        window.location.reload();

                                    });


                                })




                            })



                        });



                    }else {

                        var storageRef0 = firebase.storage().ref();
                        storageRef0.child('organiserImages/'+crDocData.oldFileName).delete().then(function () {

                            setShowLoading(true);

                            var bannerImage  = fileData;
                            var bannerFileName = (+new Date()) + '-' + bannerImage.name;

                            var storageRef = firebase.storage().ref();
                            var uploadTask = storageRef.child('organiserImages/'+bannerFileName+'').put(bannerImage);
                            uploadTask.on('state_changed', function(snapshot){

                                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log('Upload is ' + progress + '% done');
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                }
                            }, function(error) {

                            }, function () {

                                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {


                                    db.collection("organiser").doc(userId).update({

                                        "o_cr_document":downloadURL.toString(),
                                        "o_cr_document_file":bannerFileName
                                    }).then(function () {

                                        swal("Successfully Uploadoed", {
                                            icon: "success",
                                        }).then(()=>{

                                            setShowLoading(false);
                                            window.location.reload();

                                        });


                                    })




                                })



                            });




                        }).catch(function(error) {




                        });


                    }





                } else {
                    swal("ok!");
                }
            });




      //  alert(file);


    };



    React.useEffect(()=>{




    });

    React.useState(()=>{

        setShowLoading(true)
        db.collection("organiser").doc(userId).get().then((doc)=>{



            if(doc.exists){

                const orgData  = doc.data();
                const userName = orgData.o_email;
                const password = orgData.o_login_password;
                const organizerName = orgData.o_name;
                const organizationName = orgData.o_c_name;
                const organizerAddress = orgData.o_address;
                const organizerArea = orgData.o_area;
                const organizerCr = orgData.o_cr_number;
                const organizerMobileNumber = orgData.o_mobilenumber;
                const organizerStreet = orgData.o_street;
                const organizerType = orgData.o_type;
                const organizerVatNo = orgData.o_vat_number;

                if(organizerType==="private"){


                    const crDocumentFile = orgData.o_cr_document_file || "";
                    const crDocumentUrl = orgData.o_cr_document || "";
                    crDocData.oldFileName = crDocumentFile;
                    crDocData.oldFileUrl = crDocumentUrl;
                }else if(organizerType==="public"){

                }


                usernames = userName;
                passwords = password;

                setOrgData({username:userName,
                    password:password,
                    organizerName:organizerName,
                    organizationName:organizationName,
                    organizerAddress1:organizerAddress.line1,
                    organizerAddress2:organizerAddress.line2,
                    organizerAddress3:organizerAddress.line3,
                    organizerArea:organizerArea,
                    organizerCr:organizerCr,
                    organizerMobileNumber:organizerMobileNumber,
                    organizerType:organizerType,
                    organizerStreet:organizerStreet,
                    organizerVatNo:organizerVatNo
                })
                setShowLoading(false)

            }

        });


    })



    return (
        <div>
            {showLoading===true ? <div className={classes.root4}>
                <CircularProgress  />
            </div>:null}
        <form className={classes.root} noValidate autoComplete="off" onSubmit={sendUpdateData}>




            <div>
               {/* <div className={classes.root2} id="p-bar">
                    <CircularProgress />
                </div>*/}



                <div className={classes.root3}>
                    {showAlert ? <Alert  severity="success" autoHideDuration={2000}>Successfully saved</Alert>:null}

                </div>



                <Box mt={2}>



                    <FormGroup row>
                        <Box>
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "اسم المستخدم":selectedLanguage==='en' ? "Username":null}
                            type="text"
                            name="username"
                            value={orgData.username}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />
                            <TextField
                                disabled
                                required
                                id="username-input"
                                label={ selectedLanguage==='ar' ? "اسم المنظم":selectedLanguage==='en' ? "Organizer Name":null}
                                type="text"
                                name="username"
                                value={orgData.organizerName}
                                variant="outlined"
                                autoComplete="current-password"
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                className={classes.textFieldBg}
                            />
                            <TextField
                                disabled
                                required
                                id="username-input"
                                label={ selectedLanguage==='ar' ? "اسم المنظمة":selectedLanguage==='en' ? "Organisation Name":null}
                                type="text"
                                name="username"
                                value={orgData.organizationName}
                                variant="outlined"
                                autoComplete="current-password"
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                className={classes.textFieldBg}
                            />
                            <TextField
                                disabled
                                required
                                id="username-input"
                                label={ selectedLanguage==='ar' ? "رقم الهاتف المحمول":selectedLanguage==='en' ? "Mobile Number":null}
                                type="text"
                                name="username"
                                value={orgData.organizerMobileNumber}
                                variant="outlined"
                                autoComplete="current-password"
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                className={classes.textFieldBg}
                            />
                        </Box>
                    </FormGroup>

                    <FormGroup row>
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "عنوان السطر الأول":selectedLanguage==='en' ? "Address Line1":null}
                            type="text"
                            name="username"
                            value={orgData.organizerAddress1}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "عنوان السطر الثاني":selectedLanguage==='en' ? "Address Line2":null}
                            type="text"
                            name="username"
                            value={orgData.organizerAddress2}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "عنوان السطر الثالث":selectedLanguage==='en' ? "Address Line3":null}
                            type="text"
                            name="username"
                            value={orgData.organizerAddress3}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "المنطقة":selectedLanguage==='en' ? "Area":null}
                            type="text"
                            name="username"
                            value={orgData.organizerArea}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />
                    </FormGroup>

                    <FormGroup row>
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "الشارع":selectedLanguage==='en' ? "Street":null}
                            type="text"
                            name="username"
                            value={orgData.organizerStreet}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "رقم السجل التجاري":selectedLanguage==='en' ? "CR number":null}
                            type="text"
                            name="username"
                            value={orgData.organizerCr}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />
                        <TextField
                            disabled
                            required
                            id="username-input"
                            label={ selectedLanguage==='ar' ? "الرقم الضريبي":selectedLanguage==='en' ? "VAT number":null}
                            type="text"
                            name="username"
                            value={orgData.organizerVatNo}
                            variant="outlined"
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            className={classes.textFieldBg}
                        />

                        <Box pl={1} mt={1} width={256}>
                            <OutlinedInput
                                className={classes.textFieldBg}
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={orgData.password}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }

                            />
                        </Box>

                    </FormGroup>

                    <FormGroup row>

                        {/*<Box pl={1} width={256}>
                        <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={orgData.password}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }

                        />
                        </Box>*/}

                        {orgData.organizerType === "private" ?  <TextField
                            id="Cr-document"
                            label={ selectedLanguage==='ar' ? "مستند CR":selectedLanguage==='en' ? "Cr Document":null}
                            variant="outlined"
                            type="file"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={uploadCr()}
                        /> :null}

                        {orgData.organizerType === "private" ? <div>
                            <Box mt={3} ml={2}>

                                <a href={crDocData.oldFileUrl} target="_blank">

                                    { selectedLanguage==='ar' ? "عرض مستند Cr":selectedLanguage==='en' ? "View Cr Document":null}
                                </a>

                              {/*  <Button onClick={()=> window.open(crDocData.oldFileUrl, "_blank")} variant="contained">View</Button>*/}
                             </Box>
                        </div>



                            :null}

                    </FormGroup>

                    <FormGroup row>

                        <Button type="submit" variant="contained" color="primary">

                            { selectedLanguage==='ar' ? "حفظ":selectedLanguage==='en' ? "SAVE":null}
                        </Button>

                    </FormGroup>

                </Box>


            </div>

            <div>
                <Box mt={3} ml={1}>
                 {/*   <Button type="submit" variant="contained" color="primary">
                        UPDATE
                    </Button>*/}
                </Box>
                {/*<input
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={uploadCr()}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                        Upload CR
                    </Button>
                </label>*/}


            </div>
        </form></div>
    );
}