import React from 'react';

import firebase from '../services/firebase'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SignupStep2 from './SignupStep2';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import history from '../history';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
           {/* <Link color="inherit" href="https://hexcode.co/">
                Hexcode
            </Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        marginTop: theme.spacing(1),
        width: '100%',
    },

}));

export default function SignUpStep1() {

    const classes = useStyles();
    const [companyType, setCompanyType] = React.useState('');
    const [gender, setGender] = React.useState('');

    const handleChangeCompanyType = (event) => {

        setCompanyType(event.target.value);
    };

    const handleChangeGender = (event) => {

        setGender(event.target.value);
    };

    const validateDate = (event) => {

       // this.props.history.push('/thank-you');
        // alert('hi');
      /*  return(<Router>
            <Route path="/signup2"  component={SignupStep2} exact />
        </Router>)*/
    };




    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>

                    <Typography component="h1" variant="h5">
                        Alroshan
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="companyName"
                            label="Company Name"
                            name="companyName"
                            autoComplete="text"
                            autoFocus
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Company Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="companyType"
                                name="companyType"
                                value={companyType}
                                onChange={handleChangeCompanyType}
                                label="Company Type"
                            >
                                <MenuItem value={"Private"}>Private</MenuItem>
                                <MenuItem value={"Public"}>Public</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mobile Number"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Person Name"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="gender"
                                name="gender"
                                value={gender}
                                onChange={handleChangeGender}
                                label="Gender"
                            >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                            </Select>
                        </FormControl>


                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                          >

                            Next


                        </Button>

                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </form>

                </div>
            </Grid>
        </Grid>
    );
}