import React from 'react';
import logo from './logo.svg';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';

//------
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppsIcon from '@material-ui/icons/Apps';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BusinessIcon from '@material-ui/icons/Business';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import TelegramIcon from '@material-ui/icons/Telegram';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
//import {Router,Route,browserHistory} from 'react-router';
import Bookings from "./components/Bookings";
import Visitors from "./components/visitors";
import VisitorDetails from "./components/VistorDetails";
import Participants from "./components/participants";
import ParticipantDetails from "./components/ParticipantsDetails";
import ParticipantBookingDetails from "./components/ParticipientBookingDetails";
import CreateOrganizer from './components/CreateOrganizer';
import CreateBookings from './components/CreateBookings';
import Organizers from './components/Organizer'
import Dashboard from "./components/Dashboard";
import SignUpStep1 from "./components/SignupStep1";
import SignUpStep2 from "./components/SignupStep2";
import Login from "./components/Login";
import BookingsDetails from "./components/BookingsDetails";
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Hall from "./components/Hall";
import CreateHall from "./components/CreateHall";
import {createHashHistory} from 'history';
import Collapse from '@material-ui/core/Collapse';
import StarBorder from '@material-ui/icons/StarBorder';
import ListSubheader from '@material-ui/core/ListSubheader';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import OrganizerDetails from "./components/OrganizerDetails";
import OrganizerBookingDetails from "./components/OrganizerBookingDetails";
import CreateAddons from "./components/CreateAddons";
import Paper from '@material-ui/core/Paper';
import Events from "./components/Events";
import EventsEdit from "./components/EventEdit";
import EventsDetails from "./components/EventsDetails";
import EventsParticipantLeads from "./components/EventsParticipantLeads";
import Notification from "./components/Notification";
import ChangePassword from "./components/ChangePassword";
import Profile from "./components/Profile";
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockIcon from '@material-ui/icons/Lock';

import AppStyle from "./App.css";

//----------



import firebase from './services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import './App.css';

import {
    BrowserRouter as Router,
    Switch, Route, Link, useLocation
} from "react-router-dom";

import swal from 'sweetalert';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
          <Link color="inherit" href="https://hexcode.co/">
            Hexcode
          </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const db = firebase.firestore();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor:'#343344',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    root2: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
        marginLeft: 40
    },
    link: {

        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    root3: {
        height: '100vh',
    },
    image4: {
        backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/alroshan-1775a.appspot.com/o/loginImages%2Forganiser1.png?alt=media&token=37916358-a429-4604-88c4-3275ba43a5e2)',//
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',

    },
    root4: {
        height: '100vh',
    },
    paper4: {
        margin: theme.spacing(1, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form4: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit4: {
        width:'32%',
        marginLeft:'31ch',
        margin: theme.spacing(4, 0, 4),
    },
    formControl4: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    root5: {

        display: 'inline-block',
        position: 'fixed',
        marginTop:'40ch',
    },
    logOutBtn:{

        width:'88%'

    }



}));


function App() {

    const userId = localStorage.getItem("userId") || '';
    const userName = localStorage.getItem("userName") || '';
    const selectedLanguage = localStorage.getItem("lang") || 'en';

    const classes = useStyles();
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(true);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open7, setOpen7] = React.useState(false);
    const [open8, setOpen8] = React.useState(false);
    const [alertMessage,setAlertMessage] = React.useState("");
    const [loginStatus,setLoginStatus] = React.useState(false);//make it false

    const [lang,setLang] = React.useState("en");

    const [headNames,setHeadNames] = React.useState("Alroshan");

    //-------phone authentication -------

    const [phoneNumbers,setPhoneNumber] = React.useState("");
    const [verificationCode,setVerificationCode] = React.useState("");

    const handleChangeMobile = (e) => {


        if(e.target.value.length <10){

            setPhoneNumber(parseInt(e.target.value));
        }




    };

    const handleChangeVerificationCode = (e) => {

        if(e.target.value.length <7){

            setVerificationCode(e.target.value);

        }



    };
    const handleSignUp = event => {
        event.preventDefault();

        setStep({step:3})

        window.appVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible"
            }
        );


        const appVerifier = window.appVerifier;

        firebase
            .auth()
            .signInWithPhoneNumber("+966"+phoneNumbers, appVerifier)
            .then(function(confirmationResult) {
                console.log("Success");
                // alert('success')
            })
            .catch(function(error) {
                console.log("Error:" + error.code);
            });

        firebase
            .auth()
            .signInWithPhoneNumber("+91"+"9746670237", appVerifier)
            .then(function(confirmationResult) {
                console.log("Success");
                // alert('success')
            })
            .catch(function(error) {
                console.log("Error:" + error.code);
            });



    };

    const onVerifyCodeSubmit = event => {
        event.preventDefault();
        //
       /* window.appVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "visible"
            }
        );*/


        firebase.auth().signInWithPhoneNumber("+966"+phoneNumbers, window.appVerifier)
            .then(function (confirmationResult) {



                confirmationResult.confirm(verificationCode).then(function (result) {

                    var user = result.user;
                    console.log(user.phoneNumber);

                    setStep({step:1})


                   // localStorage.setItem("loginStatus",true);
                   // localStorage.setItem("uId",user.uid);
                   // localStorage.setItem("regMobile",user.phoneNumber);
                   // setLoginStatus(true)
                    // swal("Login Success");


                }).catch(function (error) {

                });


                return confirmationResult.confirm(verificationCode)
            }).catch(function (error) {

        });



    };




    //--------



    const [step,setStep] = React.useState({
        step:0
    });

    const [error1,setError1] = React.useState({
        companyName:false,
       // mobileNumber:false,
        personName:false,
        city:false,
        street:false,
        area:false,
        addressLine1:false,
        addressLine2:false,
        addressLine3:false,
        crNumber:false,
        vatNumber:false,
        crExpireDate:false,
        crDocument:false,
        emailId:false,
        passwords:false,
        companyType:false,
        gender:false,
    });


    const [orgData,setOrgData] = React.useState({
        companyName:"",
        mobileNumber:"",
        personName:"",
        city:"",
        street:"",
        area:"",
        addressLine1:"",
        addressLine2:"",
        addressLine3:"",
        crNumber:"",
        vatNumber:"",
        crExpireDate:"",
        crDocument:"",
        emailId:"",
        passwords:"",
        companyType:"",
        gender:""
    });


   // const [companyType, setCompanyType] = React.useState('');
   // const [gender, setGender] = React.useState('');



    const [loginData,selLoginData] = React.useState({
        usernames:"",
        passwords:""
    });

    const validate = () => {

        let isError = false;
        const errors = {
            companyName:false,
           // mobileNumber:true,
            personName:false,
            city:false,
            street:false,
            area:false,
            addressLine1:false,
            addressLine2:false,
            addressLine3:false,
            crNumber:false,
            vatNumber:false,
            crExpireDate:false,
            crDocument:false,
            emailId:false,
            passwords:false,
            companyType:false,
            gender:false,
        };

        if(orgData.companyType==="private"){

            if(orgData.personName===""){
                isError = true;
                errors.personName = true;
            }

          /*  if(orgData.mobileNumber===""){

                isError = true;
                errors.mobileNumber = true;

            }*/

            if(orgData.emailId===""){

                isError = true;
                errors.emailId = true;

            }

            if(orgData.passwords===""){

                isError = true;
                errors.passwords = true;

            }
            if(orgData.gender===""){

                isError = true;
                errors.gender = true;

            }
            if(orgData.addressLine1===""){

                isError = true;
                errors.addressLine1 = true;

            }
            if(orgData.addressLine2===""){

                isError = true;
                errors.addressLine2 = true;

            }
            if(orgData.addressLine3===""){

                isError = true;
                errors.addressLine3 = true;

            }
            if(orgData.city===""){

                isError = true;
                errors.city = true;

            }
            if(orgData.street===""){

                isError = true;
                errors.street = true;

            }
            if(orgData.area===""){

                isError = true;
                errors.area = true;

            }
            if(orgData.companyName===""){

                isError = true;
                errors.companyName = true;

            }

            if(orgData.crNumber===""){



                isError = true;
                errors.crNumber = true;

            }
            if(orgData.vatNumber===""){



                isError = true;
                errors.vatNumber = true;

            }
            if(orgData.crExpireDate===""){

                isError = true;
                errors.crExpireDate = true;

            }
            if(orgData.crDocument===""){



                isError = true;
                errors.crDocument = true;



            }
            if(orgData.companyType===""){

                isError = true;
                errors.companyType = true;

            }

            if(isError){

                setError1(errors)
            }



        }else if(orgData.companyType==="public"){

            if(orgData.personName===""){
                isError = true;
                errors.personName = true;
            }

           /* if(orgData.mobileNumber===""){

                isError = true;
                errors.mobileNumber = true;

            }*/

            if(orgData.emailId===""){

                isError = true;
                errors.emailId = true;

            }

            if(orgData.passwords===""){

                isError = true;
                errors.passwords = true;

            }
            if(orgData.gender===""){

                isError = true;
                errors.gender = true;

            }
            if(orgData.addressLine1===""){

                isError = true;
                errors.addressLine1 = true;

            }
            if(orgData.addressLine2===""){

                isError = true;
                errors.addressLine2 = true;

            }
            if(orgData.addressLine3===""){

                isError = true;
                errors.addressLine3 = true;

            }
            if(orgData.city===""){

                isError = true;
                errors.city = true;

            }
            if(orgData.street===""){

                isError = true;
                errors.street = true;

            }
            if(orgData.area===""){

                isError = true;
                errors.area = true;

            }
            if(orgData.companyName===""){

                isError = true;
                errors.companyName = true;

            }
            if(orgData.companyType===""){

                isError = true;
                errors.companyType = true;

            }

            if(isError){

                setError1(errors)
            }

        }else {

            if(orgData.personName===""){
                isError = true;
                errors.personName = true;
            }

           /* if(orgData.mobileNumber===""){

                isError = true;
                errors.mobileNumber = true;

            }*/

            if(orgData.emailId===""){

                isError = true;
                errors.emailId = true;

            }

            if(orgData.passwords===""){

                isError = true;
                errors.passwords = true;

            }
            if(orgData.gender===""){

                isError = true;
                errors.gender = true;

            }
            if(orgData.addressLine1===""){

                isError = true;
                errors.addressLine1 = true;

            }
            if(orgData.addressLine2===""){

                isError = true;
                errors.addressLine2 = true;

            }
            if(orgData.addressLine3===""){

                isError = true;
                errors.addressLine3 = true;

            }
            if(orgData.city===""){

                isError = true;
                errors.city = true;

            }
            if(orgData.street===""){

                isError = true;
                errors.street = true;

            }
            if(orgData.area===""){

                isError = true;
                errors.area = true;

            }
            if(orgData.companyName===""){

                isError = true;
                errors.companyName = true;

            }
            if(orgData.companyType===""){

                isError = true;
                errors.companyType = true;

            }

            if(isError){

                setError1(errors)
            }
        }


        return isError;
    };

    const changeLang = (props) => () => {

        setLang(props);
        localStorage.setItem("lang",props);

    }

    const showLoading = () => {

        setLoading(true)
    };

    const dismissLoading = () => {

        setLoading(false)
    };


    const handleDrawerOpen = () => {
        setOpen2(true);
    };

    const handleDrawerClose = () => {
        setOpen2(false);
    };

   /* const handleClick5 = () => {
        setOpen3(!open3);
    };*/


    const handleClick3 = () => {
        setOpen8(true);
    };

    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen8(false);
    };

    const handleClick4 = () => {
        setOpen1(true);
    };

    const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen1(false);
    };

    const handleClick7 = () => {

        setOpen7(true);
    };

    const handleClose7 = () => {

        setOpen7(false);
    };


    const handleClick6 = (props) => {
        setOpen4(true);
        setAlertMessage(props);
    };

    const handleClose6 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen4(false);
    };

    const goToStep1 = (e) =>{

        e.preventDefault();
       // setStep({step:1})

        setStep({step:2})

    };

    const logoutClick = (e) => {

        localStorage.clear();

        handleClick7()


        setLoginStatus(false)




    };

    const onOrgDataChange = (props) => (e) =>{

            if(props==="companyName"){

                setOrgData({companyName:e.target.value,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="mobileNumber"){






            setOrgData({companyName:orgData.companyName,
                mobileNumber:e.target.value,
                personName:orgData.personName,
                city:orgData.city,
                street:orgData.street,
                area:orgData.area,
                addressLine1:orgData.addressLine1,
                addressLine2:orgData.addressLine2,
                addressLine3:orgData.addressLine3,
                crNumber:orgData.crNumber,
                vatNumber:orgData.vatNumber,
                crExpireDate:orgData.crExpireDate,
                crDocument:orgData.crDocument,
                emailId:orgData.emailId,
                passwords:orgData.passwords,
                companyType:orgData.companyType,
                gender:orgData.gender,


            })
        }else if(props==="personName"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:e.target.value,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="city"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:e.target.value,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="street"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:e.target.value,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="area"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:e.target.value,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="addressLine1"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:e.target.value,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="addressLine2"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:e.target.value,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="addressLine3"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:e.target.value,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="crNumber"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:e.target.value,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="vatNumber"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:e.target.value,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="crExpireDate"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:e.target.value,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="crDocument"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:e.target.files[0],
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="emailId"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:e.target.value.toLowerCase(),
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                });

                db.collection("organiser").where("o_login_mail","==",e.target.value.toLowerCase()).get().then((querySnapshots)=>{

                    if(querySnapshots.size>0){


                        swal("Email already exists");
                        setOrgData({companyName:orgData.companyName,
                            mobileNumber:orgData.mobileNumber,
                            personName:orgData.personName,
                            city:orgData.city,
                            street:orgData.street,
                            area:orgData.area,
                            addressLine1:orgData.addressLine1,
                            addressLine2:orgData.addressLine2,
                            addressLine3:orgData.addressLine3,
                            crNumber:orgData.crNumber,
                            vatNumber:orgData.vatNumber,
                            crExpireDate:orgData.crExpireDate,
                            crDocument:orgData.crDocument,
                            emailId:"",
                            passwords:orgData.passwords,
                            companyType:orgData.companyType,
                            gender:orgData.gender,


                        })




                    }


                });




            }else if(props==="password"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:e.target.value,
                    companyType:orgData.companyType,
                    gender:orgData.gender,


                })
            }else if(props==="companyType"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:e.target.value,
                    gender:orgData.gender,


                })
            }else if(props==="gender"){

                setOrgData({companyName:orgData.companyName,
                    mobileNumber:orgData.mobileNumber,
                    personName:orgData.personName,
                    city:orgData.city,
                    street:orgData.street,
                    area:orgData.area,
                    addressLine1:orgData.addressLine1,
                    addressLine2:orgData.addressLine2,
                    addressLine3:orgData.addressLine3,
                    crNumber:orgData.crNumber,
                    vatNumber:orgData.vatNumber,
                    crExpireDate:orgData.crExpireDate,
                    crDocument:orgData.crDocument,
                    emailId:orgData.emailId,
                    passwords:orgData.passwords,
                    companyType:orgData.companyType,
                    gender:e.target.value,


                })
            }

    };




    const onFinalSubmit = (e) => {




        e.preventDefault();

        const validateErrors = validate();

        if(!validateErrors) {

            const errors = {
                companyName:false,
               // mobileNumber:false,
                personName:false,
                city:false,
                street:false,
                area:false,
                addressLine1:false,
                addressLine2:false,
                addressLine3:false,
                crNumber:false,
                vatNumber:false,
                crExpireDate:false,
                crDocument:false,
                emailId:false,
                passwords:false,
                companyType:false,
                gender:false,
            };
            setError1(errors);




            if(orgData.companyType==="public"){

                db.collection("organiser").where("o_login_mail","==",orgData.emailId.toLowerCase().toString()).get().then((querySnapshots)=>{

                    if(querySnapshots.size>0){

                        swal("Email already exists");


                    }else {

                        showLoading();
                        db.collection("organiser").add({

                            "o_id":"",
                            "o_name":orgData.personName,
                            "o_mobilenumber":phoneNumbers,//orgData.mobileNumber,
                            "o_address":{line1:orgData.addressLine1,line2:orgData.addressLine2,line3:orgData.addressLine3},
                            "o_c_name":orgData.companyName,
                            "o_area":orgData.area,
                            "o_city":orgData.city,
                            "o_cr_document":"",
                            "o_cr_expiry_date":"",
                            "o_cr_number":"",
                            "o_vat_number":"",
                            "o_email":orgData.emailId.toLowerCase(),
                            "o_gender":orgData.gender,
                            "o_login_mail":orgData.emailId.toLowerCase(),
                            "o_login_password":orgData.passwords,
                            "o_street":orgData.street,
                            "o_type":orgData.companyType

                        }).then(function (docRef) {

                            db.collection("organiser").doc(docRef.id).update({

                                "o_id":docRef.id

                            }).then(function () {



                                setTimeout(()=>{
                                    dismissLoading();
                                   // document.getElementById("organizer-form").reset();
                                    setStep({step:0});
                                    swal("Successfully Created Organiser").then((value) => {

                                        window.location.reload();

                                    });




                                },1000)

                            });






                        });



                    }


                });


            }

            if(orgData.companyType==="private"){

                db.collection("organiser").where("o_login_mail","==",orgData.emailId.toLowerCase().toString()).get().then((querySnapshots)=>{

                    if(querySnapshots.size>0){

                        swal("Email already exists");


                    }else {

                        showLoading();

                        var bannerImage  = orgData.crDocument;
                        var bannerFileName = (+new Date()) + '-' + bannerImage.name;

                        var storageRef = firebase.storage().ref();
                        var uploadTask = storageRef.child('organiserImages/'+bannerFileName+'').put(bannerImage);
                        uploadTask.on('state_changed', function(snapshot){

                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        }, function(error) {

                        }, function() {

                            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {

                                db.collection("organiser").add({

                                    "o_id":"",
                                    "o_name":orgData.personName,
                                    "o_mobilenumber":phoneNumbers,//orgData.mobileNumber,
                                    "o_address":{line1:orgData.addressLine1,line2:orgData.addressLine2,line3:orgData.addressLine3},
                                    "o_c_name":orgData.companyName,
                                    "o_area":orgData.area,
                                    "o_city":orgData.city,
                                    "o_cr_document":downloadURL.toString(),
                                    "o_cr_document_file":bannerFileName,
                                    "o_cr_expiry_date":orgData.crExpireDate,
                                    "o_cr_number":orgData.crNumber,
                                    "o_vat_number":orgData.vatNumber,
                                    "o_email":orgData.emailId.toLowerCase(),
                                    "o_gender":orgData.gender,
                                    "o_login_mail":orgData.emailId.toLowerCase(),
                                    "o_login_password":orgData.passwords,
                                    "o_street":orgData.street,
                                    "o_type":orgData.companyType

                                }).then(function (docRef) {

                                    db.collection("organiser").doc(docRef.id).update({

                                        "o_id":docRef.id

                                    }).then(function () {



                                        setTimeout(()=>{
                                            dismissLoading();
                                          //  document.getElementById("organizer-form").reset();
                                            setStep({step:0})
                                            swal("Successfully Created Organiser").then((value) => {

                                                window.location.reload();

                                            });

                                        },1000)

                                    });






                                });




                            });
                        });


                    }


                });



            }









        }














    };


    const updateUserInput1 = (props) => (event) => {


        if(props==="username"){

            selLoginData({usernames:event.target.value,passwords:loginData.passwords})

        }




    };

    const updateUserInput2 = (props) => (event) => {


        if(props==="password"){

            selLoginData({usernames:loginData.usernames,passwords:event.target.value})
        }


    };




    const checkLogin = e => {

        e.preventDefault();



        if(loginData.usernames===""){

            swal("Email Empty");

        }else if(loginData.passwords===""){

            swal("Password Empty");


        }else {

          //  alert("username: "+loginData.usernames+" password: "+loginData.passwords)

            db.collection("organiser").where("o_email","==",loginData.usernames.toLowerCase()).where("o_login_password","==",loginData.passwords).get().then((doc)=>{

                if(doc.size>0){

                    doc.forEach((querySnapShot) =>{

                        var datas = querySnapShot.id;
                        var orgId = querySnapShot.id

                        if(datas===""){

                        }else {

                            localStorage.setItem("userId", orgId);
                            localStorage.setItem("userName", loginData.usernames);

                            handleClick4();
                            setLoginStatus(true)





                        }



                    });


                }else{
                    handleClick3();
                    // alert('invalid username or password');
                }



            }).catch(function(error) {

                console.log("Error getting documents: ", error);
            });


        }

    };


    const signInBody =   <Router><Container component="main" maxWidth="xs">
    <CssBaseline />
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">


          { selectedLanguage==='ar' ? "تسجيل الدخول":selectedLanguage==='en' ? "Organizer Sign in":null}
      </Typography>
      <form className={classes.form} noValidate onSubmit={checkLogin}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
           /* label="Email Address"*/
            label={ selectedLanguage==='ar' ? "عنوان بريد الكتروني":selectedLanguage==='en' ? "Email Address":null}
            name="email"
            autoComplete="email"
            autoFocus
            value={loginData.usernames}
            onChange={updateUserInput1('username')}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
           /* label="Password"*/
            label={ selectedLanguage==='ar' ? "الرقم السري":selectedLanguage==='en' ? "Password":null}
            type="password"
            id="password"
            autoComplete="current-password"
            value={loginData.passwords}
            onChange={updateUserInput2('password')}
            InputLabelProps={{
                shrink: true,
            }}
        />
        {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
        />*/}
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
        >
           { selectedLanguage==='en' ? "Sign In":null}{ selectedLanguage==='ar' ? "تسجيل الدخول":null}
        </Button>
        <Grid container>
          <Grid item xs>
            {/*<Link href="#" variant="body2">
              Forgot password?
            </Link>*/}
          </Grid>
          <Grid item>
            <Link href="#" variant="body2" onClick={goToStep1}>

                { selectedLanguage==='ar' ? "لا تملك حساب؟ سجل":selectedLanguage==='en' ? "Don't have an account? Sign Up":null}
            </Link>

          </Grid>
            <Box ml={3}>
                <Link href="#" variant="body2" onClick={changeLang('ar')}>
                    { selectedLanguage==='ar' ? "عربى":selectedLanguage==='en' ? "Arabic":null}

                </Link>
            </Box>
            <Box ml={1}>
                <Link href="#" variant="body2" onClick={changeLang('en')}>
                    {"English"}
                </Link>
            </Box>
        </Grid>
      </form>
    </div>
    <Box mt={8}>
      <Copyright />
    </Box>
        <div classes={classes.root3}>

        </div>
  </Container>

        <Snackbar    open={open8} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity="error">
                Incorrect Username Password
            </Alert>
        </Snackbar>
        <Snackbar    open={open1} autoHideDuration={6000} onClose={handleClose4}>
            <Alert onClose={handleClose4} severity="success">
                Login Success
            </Alert>
        </Snackbar>

        <Snackbar    open={open7} autoHideDuration={6000} onClose={handleClose7}>
            <Alert onClose={handleClose7} severity="success">
                Logout Success
            </Alert>
        </Snackbar>



    </Router>;


    var homePage = <Router>
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open2,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open2,
                        })}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="body1" noWrap>

                        {headNames}

                      {/*  { selectedLanguage==='ar' ? "لا تملك حساب؟ سجل":selectedLanguage==='en' ? "Alroshan":null}*/}
                    </Typography>
                    <Grid container className={classes.logOutBtn} alignItems="flex-start" justify="flex-end" direction="row">
                        <Button color="inherit" onClick={logoutClick}>

                            { selectedLanguage==='ar' ? "تسجيل خروج":selectedLanguage==='en' ? "Logout":null}
                        </Button>
                    </Grid>
                   {/* <Button color="inherit" className={classes.logOutBtn}>Logout</Button>*/}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open2,
                    [classes.drawerClose]: !open2,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open2,
                        [classes.drawerClose]: !open2,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                <List>

                    <Link to="/dashboard" className={classes.link}>
                        <ListItem onClick={() => selectedLanguage==='ar' ? setHeadNames("لوحة المعلومات"):selectedLanguage==='en' ? setHeadNames('Dashboard'):null  }>

                            <ListItemIcon><AppsIcon/></ListItemIcon>
                            <ListItemText primary={ selectedLanguage==='ar' ? "لوحة المعلومات":selectedLanguage==='en' ? "Dashboard":null}/>
                        </ListItem>
                    </Link>
                    <Link to="/createBooking" className={classes.link}>
                        <ListItem onClick={() => selectedLanguage==='ar' ? setHeadNames("اضافة حدث"):selectedLanguage==='en' ? setHeadNames('Create Events'):null}>

                            <ListItemIcon><AddIcon/></ListItemIcon>
                            <ListItemText primary={ selectedLanguage==='ar' ? "اضافة حدث":selectedLanguage==='en' ? "Create Events":null}/>
                        </ListItem>
                    </Link>
                    <Link to="/bookings" className={classes.link}>
                        <ListItem onClick={() => selectedLanguage==='ar' ? setHeadNames("الحجوزات"):selectedLanguage==='en' ? setHeadNames('Bookings'):null}>

                            <ListItemIcon><LibraryBooksIcon/></ListItemIcon>
                            <ListItemText primary={ selectedLanguage==='ar' ? "الحجوزات":selectedLanguage==='en' ? "Bookings":null}/>
                        </ListItem>
                    </Link>
                    <Link to="/events" className={classes.link}>
                        <ListItem onClick={() => selectedLanguage==='ar' ? setHeadNames("الأحداث الخاصة بي"):selectedLanguage==='en' ? setHeadNames('My Events'):null}>

                            <ListItemIcon><EventAvailableIcon/></ListItemIcon>
                            <ListItemText primary={ selectedLanguage==='ar' ? "الأحداث الخاصة بي":selectedLanguage==='en' ? "My Events":null}/>
                        </ListItem>
                    </Link>





                    {/*<Link to="/changePassword" className={classes.link}>
                        <ListItem onClick={() => selectedLanguage==='ar' ? setHeadNames("تغيير كلمة المرور"):selectedLanguage==='en' ? setHeadNames('Password'):null}>
                            <ListItemIcon><LockIcon /></ListItemIcon>
                            <ListItemText primary={ selectedLanguage==='ar' ? "تغيير كلمة المرور":selectedLanguage==='en' ? "Password":null}/>
                        </ListItem>
                    </Link>*/}
                    <Link to="/profile" className={classes.link}>
                        <ListItem onClick={() => selectedLanguage==='ar' ? setHeadNames("الملف الشخصي"):selectedLanguage==='en' ? setHeadNames('Profile'):null}>
                            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                            <ListItemText primary={ selectedLanguage==='ar' ? "الملف الشخصي":selectedLanguage==='en' ? "Profile":null}/>
                        </ListItem>
                    </Link>

                </List>
                <Divider/>



                {/*<List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">

                        </ListSubheader>
                    }
                    className={classes.root2}
                >
                    <ListItem button onClick={handleClick5}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings"/>
                        {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <ListItem button onClick={handleClick5}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile"/>
                        {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={open1} timeout="auto" unmountOnExit>

                        <List component="div" disablePadding>
                            <Link to="/changePassword" className={classes.link}>
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Change Password"/>
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </List>*/}


            </Drawer>
            <main className={classes.content}>

                <div className={classes.toolbar}/>
                <Switch>
                    <Route exact path="/">
                        <Dashboard/>
                    </Route>
                    <Route exact path="/dashboard">
                        <Dashboard/>
                    </Route>

                    <Route exact path="/bookings">
                        <Bookings/>
                    </Route>

                    <Route exact path="/bookingDetails/:id/:status" component={BookingsDetails}>
                        <BookingsDetails/>
                    </Route>

                    <Route exact path="/events">
                        <Events/>
                    </Route>



                    <Route exact path="/eventEdit/:id">
                        <EventsEdit/>
                    </Route>

                    <Route exact path="/eventDetails/:id/:status">
                        <EventsDetails/>
                    </Route>

                    <Route exact path="/eventParticipantLeads/:id/:pId">
                        <EventsParticipantLeads/>
                    </Route>


                    <Route exact path="/participants">
                        <Participants/>
                    </Route>

                    <Route exact path="/participantsDetails/:id">
                        <ParticipantDetails/>

                    </Route>

                    <Route exact path="/participantBookingDetails/:id/:pId">
                        <ParticipantBookingDetails/>
                    </Route>

                    <Route exact path="/createBooking">
                        <CreateBookings/>
                    </Route>


                    <Route exact path="/organiserDetails/:id">
                        <OrganizerDetails/>
                    </Route>
                    <Route exact path="/organizerBookingDetails/:id/:oId">
                        <OrganizerBookingDetails/>
                    </Route>





                    <Route exact path="/changePassword">
                        <ChangePassword/>
                    </Route>
                    <Route exact path="/profile">
                        <Profile/>
                    </Route>


                </Switch>


            </main>
        </div>
    </Router>;

    var step1 = <Router><Grid container component="main" className={classes.root4}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={5} className={classes.image4} />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
            <div className={classes.paper4}>
                <Typography component="h1" variant="h5">

                    { selectedLanguage==='ar' ? "الروشان":selectedLanguage==='en' ? "Alroshan":null}
                </Typography>
                <form className={classes.form4} noValidate id="organizer-form" onSubmit={onFinalSubmit}>
                    <FormGroup row={true}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="companyName"
                        label={ selectedLanguage==='ar' ? "الشركة":selectedLanguage==='en' ? "Company Name":null}
                        name="companyName"
                        autoComplete="text"
                        autoFocus
                        onChange={onOrgDataChange('companyName')}
                        value={orgData.companyName}
                        style={{width: '30ch'}}
                        error={error1.companyName}
                        placeholder={ selectedLanguage==='ar' ? "الشركة":selectedLanguage==='en' ? "Company Name":null}
                    />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "الاسم (الشخص المسؤول)":selectedLanguage==='en' ? "Name(person in charge)":null}
                            type="text"
                            onChange={onOrgDataChange('personName')}
                            value={orgData.personName}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.personName}
                            placeholder={"Jhon"}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "رقم الجوال":selectedLanguage==='en' ? "Mobile Number":null}
                            onChange={onOrgDataChange('mobileNumber')}
                            value={phoneNumbers}//orgData.mobileNumber}
                            style={{width: '30ch',marginLeft:'1ch'}}
                           // error={error1.mobileNumber}
                            disabled={true}
                            placeholder={"9749038969"}
                        />


                    </FormGroup>
                    <FormGroup row={true}>


                        <FormControl variant="outlined" className={classes.formControl4}  style={{width: '30ch'}}  >
                            <InputLabel id="demo-simple-select-outlined-label">

                                { selectedLanguage==='ar' ? "نوع الشركة":selectedLanguage==='en' ? "Company Type":null}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="companyType"
                                name="companyType"
                                label={ selectedLanguage==='ar' ? "نوع الشركة":selectedLanguage==='en' ? "Company Type":null}
                                onChange={onOrgDataChange('companyType')}
                                value={orgData.companyType}
                                error={error1.companyType}

                            >
                                <MenuItem value={"private"}>

                                    { selectedLanguage==='ar' ? "نشر":selectedLanguage==='en' ? "Private":null}
                                </MenuItem>
                                <MenuItem value={"public"}>

                                    { selectedLanguage==='ar' ? "عامة":selectedLanguage==='en' ? "Public":null}
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="outlined" className={classes.formControl4}  style={{width: '30ch',marginLeft:'1ch'}}>
                            <InputLabel id="demo-simple-select-outlined-label">
                                { selectedLanguage==='ar' ? "الجنس":selectedLanguage==='en' ? "Gender":null}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="gender"
                                name="gender"
                                label={ selectedLanguage==='ar' ? "الجنس":selectedLanguage==='en' ? "Gender":null}
                                onChange={onOrgDataChange('gender')}
                                value={orgData.gender}
                                error={error1.gender}
                            >
                                <MenuItem value={"Male"}>

                                    { selectedLanguage==='ar' ? "الذكر":selectedLanguage==='en' ? "Male":null}
                                </MenuItem>
                                <MenuItem value={"Female"}>

                                    { selectedLanguage==='ar' ? "أنثى":selectedLanguage==='en' ? "Female":null}
                                </MenuItem>
                            </Select>
                        </FormControl>

                    </FormGroup>
                    <FormGroup row={true}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "المنطقة":selectedLanguage==='en' ? "Area":null}
                            onChange={onOrgDataChange('area')}
                            value={orgData.area}
                            style={{width: '30ch'}}
                            error={error1.area}
                            placeholder={ selectedLanguage==='ar' ? "المنطقة":selectedLanguage==='en' ? "Area":null}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "المدينة":selectedLanguage==='en' ? "City":null}
                            type="text"
                            onChange={onOrgDataChange('city')}
                            value={orgData.city}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.city}
                            placeholder={ selectedLanguage==='ar' ? "المدينة":selectedLanguage==='en' ? "City":null}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "الشارع":selectedLanguage==='en' ? "Street":null}
                            type="text"
                            onChange={onOrgDataChange('street')}
                            value={orgData.street}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.street}
                            placeholder={ selectedLanguage==='ar' ? "الشارع":selectedLanguage==='en' ? "Street":null}
                        />

                    </FormGroup>
                    <FormGroup row={true}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "عنوان السطر الأول":selectedLanguage==='en' ? "Address Line1":null}
                            onChange={onOrgDataChange('addressLine1')}
                            value={orgData.addressLine1}
                            style={{width: '30ch'}}
                            error={error1.addressLine1}
                            placeholder={ selectedLanguage==='ar' ? "عنوان السطر الأول":selectedLanguage==='en' ? "Address Line1":null}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "عنوان السطر الثاني":selectedLanguage==='en' ? "Address Line2":null}
                            type="text"
                            onChange={onOrgDataChange('addressLine2')}
                            value={orgData.addressLine2}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.addressLine2}
                            placeholder={ selectedLanguage==='ar' ? "عنوان السطر الثاني":selectedLanguage==='en' ? "Address Line2":null}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={ selectedLanguage==='ar' ? "عنوان السطر الثالث":selectedLanguage==='en' ? "Address Line3":null}
                            onChange={onOrgDataChange('addressLine3')}
                            value={orgData.addressLine3}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.addressLine3}
                            placeholder={ selectedLanguage==='ar' ? "عنوان السطر الثالث":selectedLanguage==='en' ? "Address Line3":null}
                        />

                    </FormGroup>
                    <FormGroup row={true}>

                        { orgData.companyType === "private" ?   <TextField
                            required
                            id="crNumber"
                            label={ selectedLanguage==='ar' ? "رقم السجل التجاري":selectedLanguage==='en' ? "CR number":null}
                            defaultValue=""
                            variant="outlined"
                            name="crNumber"
                            onChange={onOrgDataChange('crNumber')}
                            value={orgData.crNumber}
                            style={{width: '30ch'}}
                            error={error1.crNumber}
                            placeholder={ selectedLanguage==='ar' ? "رقم السجل التجاري":selectedLanguage==='en' ? "CR number":null}
                        />  :null}

                        { orgData.companyType === "private" ?   <TextField
                            required
                            id="vatNumber"
                            label={ selectedLanguage==='ar' ? "الرقم الضريبي":selectedLanguage==='en' ? "VAT number":null}
                            defaultValue=""
                            variant="outlined"
                            name="vatNumber"
                            onChange={onOrgDataChange('vatNumber')}
                            value={orgData.vatNumber}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.vatNumber}
                            placeholder={ selectedLanguage==='ar' ? "الرقم الضريبي":selectedLanguage==='en' ? "VAT number":null}
                        />  :null}

                        { orgData.companyType === "private" ?    <TextField
                            required
                            id="expireDate"
                            label={ selectedLanguage==='ar' ? "تاريخ انتهاء السجل التجاري":selectedLanguage==='en' ? "CR Expire Date":null}
                            variant="outlined"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="expireDate"
                            onChange={onOrgDataChange('crExpireDate')}
                            value={orgData.crExpireDate}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.crExpireDate}
                            placeholder={ selectedLanguage==='ar' ? "تاريخ انتهاء السجل التجاري":selectedLanguage==='en' ? "CR Expire Date":null}
                        />  :null}



                    </FormGroup>
                    <FormGroup row={true}>

                        { orgData.companyType === "private" ?     <TextField
                            id="doc-file"
                            label={ selectedLanguage==='ar' ? "مستند السجل التجاري":selectedLanguage==='en' ? "CR Document":null}
                            variant="outlined"
                            type="file"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onOrgDataChange('crDocument')}
                            style={{width: '30ch',marginTop:'2ch'}}
                            error={error1.crDocument}
                            placeholder={ selectedLanguage==='ar' ? "مستند السجل التجاري":selectedLanguage==='en' ? "CR Document":null}
                        />  :null}




                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={ selectedLanguage==='ar' ? "البريد الإلكتروني":selectedLanguage==='en' ? "Email":null}
                            autoComplete="text"
                            onChange={onOrgDataChange('emailId')}
                            value={orgData.emailId}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.emailId}
                            placeholder={ selectedLanguage==='ar' ? "البريد الإلكتروني":selectedLanguage==='en' ? "Email":null}
                        />


                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={ selectedLanguage==='ar' ? "الرقم السري":selectedLanguage==='en' ? "Password":null}
                            type="password"
                            onChange={onOrgDataChange('password')}
                            value={orgData.password}
                            style={{width: '30ch',marginLeft:'1ch'}}
                            error={error1.passwords}
                            placeholder={ selectedLanguage==='ar' ? "الرقم السري":selectedLanguage==='en' ? "Password":null}
                        />




                    </FormGroup>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit4}
                        >
                        { selectedLanguage==='ar' ? "تسجيل":selectedLanguage==='en' ? "Register":null}
                    </Button>
                    <Box ml={42} >
                    <a href="/">{ selectedLanguage==='ar' ? "تسجيل الدخول":selectedLanguage==='en' ? "Login":null}</a>
                    </Box>

                </form>
                <Snackbar    open={open4} autoHideDuration={6000} onClose={handleClose6}>
                    <Alert onClose={handleClose6} severity="error">
                        {alertMessage}
                    </Alert>
                </Snackbar>

                <div className={classes.root5}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '800ms' : '0ms'
                        }}

                    >
                        <CircularProgress />
                    </Fade>
                </div>


            </div>
        </Grid>
    </Grid>



    </Router>;


    var loginPage1 = <div><Grid container component="main" className={classes.root3}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image4} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">

                    { selectedLanguage==='ar' ? "":selectedLanguage==='en' ? "":null}
                </Typography>
                <form className={classes.form} noValidate>


                    <FormGroup row>
                        <Button variant="outlined"
                                style={{height:'55px',width:'50px',marginTop:'10px',marginLeft:'20ch',color:'#000000',marginRight:'0px'}}
                        >+966</Button>
                        <TextField
                            variant="outlined"
                            color="primary"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={ selectedLanguage==='ar' ? "رقم الهاتف":selectedLanguage==='en' ? "Mobile Number":null}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={phoneNumbers} onChange={handleChangeMobile}
                            style={{height:'40px',width:'200px',marginTop:'-55px',marginLeft:'28ch'}}
                        />
                    </FormGroup>


                    <Button

                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSignUp}
                        style={{height:'40px',width:'265px',marginTop:'20px',marginLeft:'20ch'}}
                    >
                        { selectedLanguage==='ar' ? "استمر":selectedLanguage==='en' ? "Continue":null}

                    </Button>
                    <Box ml={30}>
                        <a href="/"  onClick={changeLang('ar')}>

                        </a>

                        <a href="/"  onClick={changeLang('en')}  style={{marginLeft:'1ch'}} >


                        </a>

                    </Box>




                </form>
                <div id="recaptcha-container" style={{height:'200px',width:'200px',marginTop:'2px'}}>
                </div>
            </div>
        </Grid>
    </Grid>
    </div>;

    var verifiePage1 = <div><Grid container component="main" className={classes.root3}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image4} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">

                    { selectedLanguage==='ar' ? " تحقق من Otp":selectedLanguage==='en' ? " Verify Otp":null}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={ selectedLanguage==='ar' ? " Otp":selectedLanguage==='en' ? "Otp":null}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        style={{height:'40px',width:'200px',marginTop:'10px',marginLeft:'25ch'}}
                        value={verificationCode} onChange={handleChangeVerificationCode}
                    />
                    <Button

                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onVerifyCodeSubmit}
                        style={{height:'40px',width:'200px',marginTop:'20px',marginLeft:'25ch'}}
                    >

                        { selectedLanguage==='ar' ? " تحقق":selectedLanguage==='en' ? "Verify":null}
                    </Button>

                </form>
                <div id="recaptcha-container" style={{height:'200px',width:'200px',marginTop:'2px'}}>
                </div>
            </div>
        </Grid>
    </Grid>
    </div>;




    if(loginStatus){

        return homePage;


    }else {


        if(userId===""){

            if(step.step===0){

                return (signInBody);


            }else if(step.step===1){

                return (step1);


            }else if(step.step===2){




                return loginPage1
            }else if(step.step===3){

                return verifiePage1
            }

        }else{

            setLoginStatus(true)
        }





    }


}

export default App;
