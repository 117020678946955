

import React from 'react';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from '../services/firebase';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



function createBlockData(blockCount,blockId,blockPrice,blockLabelName,participantId,participantName,participantStatus) {

    return {blockCount,blockId,blockPrice,blockLabelName,participantId,participantName,participantStatus}
}

function createEventLeads(leadCount,cId,cName,cPhone,sId,sName) {

    return{leadCount,cId,cName,cPhone,sId,sName}
}


var rows = [];
var eventId = "";
var participantId;

const db = firebase.firestore();






const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },


    }),
);

function getBookingDate(beId,bCount,callback) {


    db.collection("booking").doc(beId).collection("booking_dates").get().then(function (querySnapshot) {

        var callBackString = {};
        querySnapshot.forEach(function (doc) {


            var bDate = doc.data();
            callBackString.bookingDate = bDate.booked_date;

        });
        callback(callBackString);



    });


}



export default function EventsParticipantLeads() {
    const classes = useStyles();

    const [state,setState] = React.useState();

    var [participantBlocks,setParticipantBlocks] = React.useState([]);
    var [eventLeads,setEventLeads] = React.useState([]);


    eventId = useParams().id;
    participantId = useParams().pId;

    const selectedLanguage = localStorage.getItem("lang") || 'en';


    db.collection("booking").doc(eventId).collection("event_blocks").where("b_participent_id","==",participantId).get().then((querySnapShots)=>{

        var blockCount = 0;
        participantBlocks = [];
        querySnapShots.forEach((doc)=>{

            blockCount++;

            const blockData = doc.data();

            const blockId = blockData.b_id;
            const blockPrice = blockData.b_label_price;
            const blockLabelName = blockData.b_lable_name;
            const participantId = blockData.b_participent_id;
            const participantName = blockData.b_participent_name;
            const participantStatus = blockData.b_participient_status;

            participantBlocks.push(createBlockData(blockCount,blockId,blockPrice,blockLabelName,participantId,participantName,participantStatus))



        });
        setParticipantBlocks(participantBlocks)

    });


    db.collection("booking").doc(eventId).collection("event_leads").where("participant_id","==",participantId).get().then((querySnapShots)=>{

        var leadCount = 0;
        eventLeads = [];
        querySnapShots.forEach((doc)=>{

            leadCount++;

            const leadData = doc.data();

            const cId = leadData.c_id;
            const cName = leadData.c_name;
            const cPhone = leadData.c_reg_mobile;
            const sId = leadData.s_id;
            const sName = leadData.s_name;

            eventLeads.push(createEventLeads(leadCount,cId,cName,cPhone,sId,sName))

        });
        setEventLeads(eventLeads);
    });



    return (<div>
            <Box mt={2}>
                <Typography color="textSecondary">

                    { selectedLanguage==='ar' ? "كتل الحدث المشارك":selectedLanguage==='en' ? "Participant Event Blocks":null}
                </Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "رقم":selectedLanguage==='en' ? "SI.No":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "تسمية كتلة":selectedLanguage==='en' ? "Block Label":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "سعر كتلة التسمية":selectedLanguage==='en' ? "Block Label Price":null}
                                </StyledTableCell>
                                {/*<StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "اسم المشارك":selectedLanguage==='en' ? "Participant Name":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "الحالة":selectedLanguage==='en' ? "Status":null}
                                </StyledTableCell>*/}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {participantBlocks.map((row) => (
                                <StyledTableRow key={row.blockId} component={Link} className={classes.link} to={'/eventParticipantLeads/'+eventId+'/'+row.participantId}>
                                    <StyledTableCell align="left">{row.blockCount}</StyledTableCell>
                                    <StyledTableCell align="left">{row.blockLabelName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.blockPrice}</StyledTableCell>
                                 {/*   <StyledTableCell align="left">{row.participantName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.participantStatus}</StyledTableCell>*/}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box mt={0}>
                <Typography color="textSecondary">
                    { selectedLanguage==='ar' ? "يؤدي المشارك":selectedLanguage==='en' ? "Participant Leads":null}
                </Typography>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "رقم":selectedLanguage==='en' ? "SI.No":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "المستعمل":selectedLanguage==='en' ? "User":null}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "رقم الجوال":selectedLanguage==='en' ? "Phone Number":null}
                                </StyledTableCell>
                              {/*  <StyledTableCell align="left">
                                    { selectedLanguage==='ar' ? "اسم الأمان":selectedLanguage==='en' ? "Security Name":null}
                                </StyledTableCell>*/}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eventLeads.map((row) => (
                                <StyledTableRow key={row.cId}>
                                    <StyledTableCell align="left">{row.leadCount}</StyledTableCell>
                                    <StyledTableCell align="left">{row.cName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.cPhone}</StyledTableCell>
                                  {/*  <StyledTableCell align="left">{row.sName}</StyledTableCell>*/}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
}
