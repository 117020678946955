import React from 'react';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from '../services/firebase'
import Button from '@material-ui/core/Button';


import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(cId,cName,cEmail,cGender,phone,nationalId,dob,imageUrl) {
    return { cId,cName,cEmail,cGender,phone,nationalId,dob,imageUrl };
}

var rows = [];

const db = firebase.firestore();




/*const useStyles = makeStyles({
 table: {
 minWidth: 700,
 },
 link:{

 textDecoration:'none',
 color:theme.palette.text.primary

 },
 });*/

const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },


    }),
);



export default function VisitorDetails() {

    const classes = useStyles();
    const [state,setState] = React.useState("");

    var visitorId = useParams().id;

    var dataBody = <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell align="left">Phone</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Gender</StyledTableCell>
                    <StyledTableCell align="left">DOB</StyledTableCell>
                    <StyledTableCell align="left">National Id</StyledTableCell>
                    <StyledTableCell align="left">Image</StyledTableCell>



                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                        <StyledTableCell align="left">{row.cName}</StyledTableCell>
                        <StyledTableCell align="left">{row.phone}</StyledTableCell>
                        <StyledTableCell align="left">{row.cEmail}</StyledTableCell>
                        <StyledTableCell align="left">{row.cGender}</StyledTableCell>
                        <StyledTableCell align="left">{row.dob}</StyledTableCell>
                        <StyledTableCell align="left">{row.nationalId}</StyledTableCell>
                        <StyledTableCell align="left">
                            <a href={row.imageUrl} target="_blank" style={{ textDecoration:'none'}}><Button variant="contained">View</Button></a>

                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>;

    db.collection("participients_visitors").doc(visitorId).get().then((doc) => {

        rows = [];
        if(doc.exists){

            var ids = doc.id;
            console.log(ids);

            const data = doc.data();
            var cId = data.c_id;
            var cName = data.c_name;
            var cEmail = data.c_email;
            var cGender = data.c_gender;
            var phone = data.c_phone;
            var nationalId = data.c_national_id;
            var dob = data.c_dob;
            var imageUrl = data.c_image;



            rows.push(createData(cId,cName,cEmail,cGender,phone,nationalId,dob,imageUrl));
            setState({
                dataBody
            })



        }else{



        }










    });






    return dataBody;
}
