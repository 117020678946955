import React from 'react';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from '../services/firebase'

import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(cId,cName,cEmail,cGender,phone,nationalId,dob) {
    return { cId,cName,cEmail,cGender,phone,nationalId,dob };
}

const rows = [
];

const db = firebase.firestore();
db.collection("participients_visitors").get().then((querySnapshot) => {


    querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);

        var ids = doc.id;
        console.log(ids);

        const data = doc.data();
        var cId = data.c_id;
        var cName = data.c_name;
        var cEmail = data.c_email;
        var cGender = data.c_gender;
        var phone = data.c_phone;
        var nationalId = data.c_national_id;
        var dob = data.c_dob;



        rows.push(createData(cId,cName,cEmail,cGender,phone,nationalId,dob))



    });
    // this.setState({customers});





});



const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },


    }),
);

export default function Vistors() {
    const classes = useStyles();




    return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Gender</StyledTableCell>
                <StyledTableCell align="left">DOB</StyledTableCell>
                <StyledTableCell align="left">National Id</StyledTableCell>



              </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <StyledTableRow key={row.name} component={Link} className={classes.link} to={'/visitorDetails/'+row.cId}>
                      <StyledTableCell align="left">{row.cName}</StyledTableCell>
                      <StyledTableCell align="left">{row.phone}</StyledTableCell>
                      <StyledTableCell align="left">{row.cEmail}</StyledTableCell>
                      <StyledTableCell align="left">{row.cGender}</StyledTableCell>
                      <StyledTableCell align="left">{row.dob}</StyledTableCell>
                      <StyledTableCell align="left">{row.nationalId}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
    );
}
