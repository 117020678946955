import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'



var firebaseConfig = {
    apiKey: "AIzaSyAE3-HEVh0dxzNeuEUDmOfLK500j-6RPnI",
    authDomain: "alroshan-1775a.firebaseapp.com",
    databaseURL: "https://alroshan-1775a.firebaseio.com",
    projectId: "alroshan-1775a",
    storageBucket: "alroshan-1775a.appspot.com",
    messagingSenderId: "780102945155",
    appId: "1:780102945155:web:86f11beae6d1aea5a98eb6",
    measurementId: "G-6HK0DWWH0W"
};


firebase.initializeApp(firebaseConfig);
//firebase.analytics();



export const auth = firebase.auth();
export const db = firebase.firestore();

export default firebase;