import React from 'react';
//import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import firebase from '../services/firebase';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';



import {
    BrowserRouter as Router,
    Switch, Route,Link,useParams
} from "react-router-dom";

function Alert(props) {
    return <MuiAlert elevation={20} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



function createAddons(aCount,adStype,adDescription,adSAmount,docId) {
    return { aCount,adStype,adDescription,adSAmount,docId };
}



const db = firebase.firestore();

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    root3: {

        display: 'inline-block',
        position: 'fixed',
        paddingLeft:'40%',
    },
});



var adName = [{ name:""}];
var adDescription = [{ mobileNumber:""}];
var adPrice = [{ email:""}];




export default function CreateAddons() {

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [open1,setOpen1] = React.useState(false);
    const [open2,setOpen2] = React.useState(false);

    const [adName,setAdName] = React.useState("");
    const [adDescription,setAdDescription] = React.useState("");
    const [adPrice,setAdPrice] = React.useState("");

    const [values, setValues] = React.useState({
        adName: '',
        adDescription: '',
        adPrice: '',
    });
    var [adsArray,setadsArray] = React.useState([]);


    const showLoading = () => {

          setLoading(true)
    }

    const dismissLoading = () => {

          setLoading(false)
    }


    const handleClick3 = () => {
        setOpen1(true);
    };

    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen1(false);
    };

    const handleClick4 = () => {
        setOpen2(true);
    };

    const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen2(false);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (prop) => (event) => {

        setValues({...values,[prop]: event.target.value})


    };

    const deleteAddons = (prop) => (event) => {

         showLoading();
         db.collection("ad_on_service").doc(prop).delete().then((res)=>{

             handleClick4();
           //  alert("Successfully Deleted")
             dismissLoading();
         })

    };




    const addAddons = e => {

        e.preventDefault();

        if(values.adName===""){
            alert('Name Required');

        }else if(values.adDescription==="") {

            alert('Description Required')


        }else if(values.adPrice==="") {

            alert('Price Required')

        }else {

            showLoading();
            handleClose();

            db.collection("ad_on_service").add({

                "ad_s_id":"",
                "ad_s_type":values.adName,
                "ad_s_description":values.adDescription,
                "ad_s_amount":values.adPrice

            }).then((doc) => {

                db.collection("ad_on_service").doc(doc.id).update({
                  "ad_s_id":doc.id
                }).then(function () {

                    handleClick3();
                    handleClose();
                    dismissLoading();

                })

            })

        }


    };



    db.collection("ad_on_service").get().then((querySnapshot) => {




        if(querySnapshot.size > 0){


            var i = 0;
            adsArray = [];
            querySnapshot.forEach((doc) =>{

                i++;

                var docId = doc.id;

                var addonData = doc.data();
                var adStype = addonData.ad_s_type;
                var adDescription = addonData.ad_s_description;
                var adSAmount = addonData.ad_s_amount;


                adsArray.push(createAddons(i,adStype,adDescription,adSAmount,docId))



            });
            setadsArray(adsArray);




        }else {


          //  alert("No booking found")

        }



    });








    return (<div>

        <Box mt={2}>
            <div className={classes.root3}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '800ms' : '0ms'
                    }}

                >
                    <CircularProgress />
                </Fade>
            </div>
            <Typography color="textSecondary">
                Addons
            </Typography>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Create
            </Button>

            <Box mt={2}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell>SI.No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Descriptions</TableCell>
                            <TableCell>Action</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {adsArray.map((row) => (

                            <TableRow key={row.docId}>

                                <TableCell>{row.aCount}</TableCell>
                                <TableCell>{row.adStype}</TableCell>
                                <TableCell>{row.adSAmount}</TableCell>
                                <TableCell>{row.adDescription}</TableCell>
                                <TableCell><IconButton aria-label="delete" onClick={deleteAddons(row.docId)}>
                                    <DeleteIcon />
                                </IconButton></TableCell>


                            </TableRow>

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Box>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form  noValidate autoComplete="off" onSubmit={addAddons}>
            <DialogTitle id="form-dialog-title">Create Addon Services</DialogTitle>
            <DialogContent>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={values.adName}
                    onChange={handleChange('adName')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="price"
                    label="Price"
                    type="text"
                    fullWidth
                    value={values.adPrice}
                    onChange={handleChange('adPrice')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    value={values.adDescription}
                    onChange={handleChange('adDescription')}
                />
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    SAVE
                </Button>
                <Button onClick={handleClose} color="primary">
                    CLOSE
                </Button>
            </DialogActions>
            </form>
        </Dialog>
        <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity="success">
                Success fully Added
            </Alert>
        </Snackbar>

        <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose4}>
            <Alert onClose={handleClose4} severity="success">
                Success fully Deleted
            </Alert>
        </Snackbar>





    </div>);

}