import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        root2: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            paddingLeft:'30%',
            paddingTop:'15%',
            zIndex:10,
            position: 'fixed',
            visibility:'hidden'

        },
    }),
);

const db = firebase.firestore();
const storage = firebase.storage();



export default function CreateOrganizer() {
    const classes = useStyles();
    const [genders, setGender,setState] = React.useState('Male');



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(event.target.value);

        gender = event.target.value;
        const setState = ({

            [event.target.name]: event.target.value

        });


    };




    var name = [{ name:""}];
    var mobileNumber = [{ mobileNumber:""}];
    var email = [{ email:""}];
    var password = [{ password:""}];
    var gender = [{ gender:""}];
    var addressLine1 = [{ addressLine1:""}];
    var addressLine2 = [{ addressLine2:""}];
    var addressLine3 = [{ addressLine3:""}];
    var city = [{ city:""}];
    var street = [{ street:""}];
    var area = [{ area:""}];
    var companyName = [{ companyName:""}];
    var crNumber = [{ crNumber:""}];
    var vatNumber = [{ vatNumber:""}];
    var expireDate = [{ expireDate:""}];

    var organiserFiles = [{ organiserFiles:"" }]


    const updateNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {

          name[0].name = event.target.value;





    };
    const updatemobileNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        mobileNumber[0].mobileNumber = event.target.value;
    };
    const updateemailInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        email[0].email = event.target.value;
    };

    const updatpasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        password[0].password = event.target.value;

    };



    const updategenderInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        gender[0].gender = event.target.value;

    };
    const updateaddressLine1Input = (event: React.ChangeEvent<HTMLInputElement>) => {

        addressLine1[0].addressLine1 = event.target.value;

    };
    const updateaddressLine2Input = (event: React.ChangeEvent<HTMLInputElement>) => {

        addressLine2[0].addressLine2 = event.target.value;

    };
    const updateaddressLine3Input = (event: React.ChangeEvent<HTMLInputElement>) => {

        addressLine3[0].addressLine3 = event.target.value;

    };

    const updatecityInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        city[0].city = event.target.value;

    };

    const updatestreetInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        street[0].street = event.target.value;

    };

    const updateareaInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        area[0].area = event.target.value;

    };

    const updatecompanyNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        companyName[0].companyName = event.target.value;

    };

    const updatecrNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        crNumber[0].crNumber = event.target.value;

    };

    const updatevatNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        vatNumber[0].vatNumber = event.target.value;

    };

    const updateexpireDateInput = (event: React.ChangeEvent<HTMLInputElement>) => {

        expireDate[0].expireDate = event.target.value;

    };

    const updateFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {



        organiserFiles[0].organiserFiles = event.target.files[0];

    };



    const addBooking = e => {

        e.preventDefault();

        if(name[0].name===""){

            return(<Alert severity="error">This is an error message!</Alert>)


        }else {

            document.getElementById("p-bar").style.visibility = 'visible';

            var bannerImage  = organiserFiles[0].organiserFiles;
            var bannerFileName = (+new Date()) + '-' + bannerImage.name;

            var storageRef = firebase.storage().ref();
            var uploadTask = storageRef.child('organiserImages/'+bannerFileName+'').put(bannerImage);
            uploadTask.on('state_changed', function(snapshot){

                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function(error) {

            }, function() {

                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {

                    db.collection("organiser").add({

                        "o_id":"",
                        "o_name":name[0].name,
                        "o_mobilenumber":mobileNumber[0].mobileNumber,
                        "o_address":{line1:addressLine1[0].addressLine1,line2:addressLine2[0].addressLine2,line3:addressLine3[0].addressLine3},
                        "o_c_name":companyName[0].companyName,
                        "o_area":area[0].area,
                        "o_city":city[0].city,
                        "o_cr_document":downloadURL.toString(),
                        "o_cr_expiry_date":expireDate[0].expireDate,
                        "o_cr_number":crNumber[0].crNumber,
                        "o_vat_number":vatNumber[0].vatNumber,
                        "o_email":email[0].email,
                        "o_gender":gender[0].gender,
                        "o_login_mail":"",
                        "o_login_password":password[0].password,
                        "o_street":street[0].street,
                        "o_type":"(private/public)"

                    }).then(function (docRef) {

                        db.collection("organiser").doc(docRef.id).update({

                            "o_id":docRef.id

                        }).then(function () {

                            document.getElementById("p-bar").style.visibility = 'hidden';
                            document.getElementById("organizer-form").reset();


                        });






                    });




                });
            });




        }








    };



    return (
        <form className={classes.root} id="organizer-form" noValidate autoComplete="off" onSubmit={addBooking}>
            <div>
                <div className={classes.root2} id="p-bar">
                    <CircularProgress />
                </div>
                <FormGroup row>
               <TextField
                    required
                    id="standard-name-input"
                    label="Name"
                    type="text"
                    name="name"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateNameInput}
                    value={name.name}
                />
                <TextField
                    id="standard-organization-name-input"
                    label="Mobile Number"
                    type="phone"
                    name="mobileNumber"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updatemobileNumberInput}
                    value={mobileNumber.mobileNumber}
                />
                <TextField
                    id="standard-email-input"
                    label="Email"
                    type="email"
                    name="email"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateemailInput}
                    value={email.email}
                />
                    <TextField
                        id="standard-password-input"
                        label="Password"
                        type="password"
                        name="password"
                        variant="outlined"
                        autoComplete="current-password"
                        onChange={updatpasswordInput}
                        value={password.password}
                    />

                <TextField
                    id="standard-gender-input"
                    select
                    label=""
                    name="gender"
                    value={genders}
                    onChange={updategenderInput}
                    helperText=""
                    variant="outlined">
                    {[{
                        value: 'Male',
                        label: 'Male',
                    },
                        {
                            value: 'Female',
                            label: 'Female',
                        },].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                </FormGroup>
            </div>
            <div>

                <TextField
                    id="standard-address-line1-input"
                    label="AddressLine1"
                    type="text"
                    name="addressLine1"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateaddressLine1Input}
                    value={addressLine1.addressLine1}
                />
                <TextField
                    id="standard-address-line2-input"
                    label="AddressLine2"
                    type="text"
                    name="addressLine2"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateaddressLine2Input}
                    value={addressLine2.addressLine2}
                />
                <TextField
                    id="standard-address-line3-input"
                    label="AddressLine3"
                    type="text"
                    name="addressLine3"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateaddressLine3Input}
                    value={addressLine3.addressLine3}
                />
                <TextField
                    id="standard-city-input"
                    label="City"
                    type="text"
                    name="city"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updatecityInput}
                    value={city.city}
                />
                <TextField
                    id="standard-street-input"
                    label="Street"
                    type="text"
                    name="street"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updatestreetInput}
                    value={street.street}
                />
                <TextField
                    id="standard-area-input"
                    label="Area"
                    type="text"
                    name="area"
                    variant="outlined"
                    autoComplete="current-password"
                    onChange={updateareaInput}
                    value={area.area}
                />
            </div>
            <div>

            </div>
            <div>
                <TextField
                    required
                    id="company-name"
                    label="Company Name"
                    defaultValue=""
                    name="companyName"
                    variant="outlined"
                    onChange={updatecompanyNameInput}
                    value={companyName.companyName}
                />
                <TextField
                    required
                    id="crNumber"
                    label="CR number"
                    defaultValue=""
                    variant="outlined"
                    name="crNumber"
                    onChange={updatecrNumberInput}
                    value={crNumber.crNumber}
                />
                <TextField
                    required
                    id="vatNumber"
                    label="VAT number"
                    defaultValue=""
                    variant="outlined"
                    name="vatNumber"
                    onChange={updatevatNumberInput}
                    value={vatNumber.vatNumber}
                />

                <TextField
                    required
                    id="expireDate"
                    label="CR Expire Date"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name="expireDate"
                    onChange={updateexpireDateInput}
                    value={expireDate.expireDate}
                />

                <TextField

                    id="doc-file"
                    label="CR Document"
                    variant="outlined"
                    type="file"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={updateFileSelection}
                />





            </div>
            <div>
                <Box mt={3} ml={1}>
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
                </Box>
            </div>
        </form>
    );
}