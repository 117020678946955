import React from 'react';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import firebase from '../services/firebase'
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import history from '../history';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { spacing } from '@material-ui/system';
import MuiAlert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';

import swal from 'sweetalert';


import {
    BrowserRouter as Router,
    Switch, Route, Link
} from "react-router-dom";


const db = firebase.firestore();
const storage = firebase.storage();

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#37474f',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



function createData(eCount,bookingData,eId,eName,oName,hallName,eStatus) {
    return { eCount,bookingData,eId,eName,oName,hallName,eStatus };
}

function createBlockData(bCount,bId,bLabelName,bLabelPrice,participantId,participantName,oStatus) {
    return { bCount,bId,bLabelName,bLabelPrice,participantId,participantName,oStatus };
}

const rows = [];







function getBookingDate(beId,bCount,callback) {


    db.collection("booking").doc(beId).collection("booking_dates").get().then(function (querySnapshot) {

        var callBackString = {};
        querySnapshot.forEach(function (doc) {


            var bDate = doc.data();
            callBackString.bookingDate = bDate.booked_date;

        });
        callback(callBackString);



    });


}


function Alert(props) {

    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },
        root2: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
            paddingLeft:'40%',
            paddingTop:'15%',
            zIndex:10,
            position: 'fixed',
            visibility:'hidden'

        },
        root3:{
            width: '45ch',
            float:'right'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        root4: {
            position:'fixed',
            display: 'flex',
            justifyContent:'center',
            marginTop:'60px',
            marginLeft:'40%',
            zIndex:1,
        },

    }),
);





function Bookings() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [showAlert,setShowAlert] = React.useState(false);

    const userId = localStorage.getItem("userId") || '';
    const userName = localStorage.getItem("userName") || '';
    const selectedLanguage = localStorage.getItem("lang") || 'en';
    const [showLoading,setShowLoading] = React.useState(false);

    var [activeBooking,setActiveBooking] = React.useState([]);
    var [blockData,setBlockData] = React.useState([]);

   // const [selectedEventName,setSelectedEventName] = React.useState("Select Active Event");



        const [selectedEventName,setSelectedEventName] = React.useState(selectedLanguage==="en"?"Select Active Event":selectedLanguage==='ar'?"حدد الحدث النشط":null);




   // const [selectedStatus,setSelectedStatus] = React.useState("Select Status");
    const [selectedStatus,setSelectedStatus] = React.useState(selectedLanguage==="en"?"Select Status":selectedLanguage==='ar'?"حدد الحالة":null);

    const [selectedEventId,setSelectedEventId] = React.useState("");





    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = props => (event) => {
        setAnchorEl(null);

        const propsData = props.split("|");
        setSelectedEventName(propsData[1]);


       // alert(props);

        setSelectedEventId(propsData[0]);
        setShowLoading(true);
        db.collection("booking").doc(propsData[0]).collection("event_blocks").get().then((querySnapshot) => {

            var blockCount = 0;
            blockData = [];
            querySnapshot.forEach((doc) => {


                blockCount++;

                var ids = doc.id;

                const blockDatas = doc.data();
                var bId = blockDatas.b_id;
                var bLabelName = blockDatas.b_lable_name;
                var bLabelPrice = blockDatas.b_label_price;
                var bParticipantId = blockDatas.b_participent_id;
                var oStatus = blockDatas.o_status;
                var bParticipantName = blockDatas.b_participent_name || "Not Booked";
                var bParticipantStatus = blockDatas.b_participient_status || "Not Booked";

                blockData.push(createBlockData(blockCount,bId,bLabelName,bLabelPrice,bParticipantId,bParticipantName,bParticipantStatus));

            });
            setBlockData(blockData);
            setShowLoading(false);



        })





    };

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };


    const handleClose1 = props => (event) => {
        setAnchorEl1(null);

        //alert(props+" "+selectedEventId);

        setSelectedStatus(props);
        setShowLoading(true);
        db.collection("booking").doc(selectedEventId).collection("event_blocks").where("b_participient_status","==",props).get().then((querySnapshot) => {

            var blockCount = 0;
            blockData = [];
            querySnapshot.forEach((doc) => {


                blockCount++;

                var ids = doc.id;

                const blockDatas = doc.data();
                var bId = blockDatas.b_id;
                var bLabelName = blockDatas.b_lable_name;
                var bLabelPrice = blockDatas.b_label_price;
                var bParticipantId = blockDatas.b_participent_id;
                var bParticipantName = blockDatas.b_participent_name;
                var bParticipantStatus = blockDatas.b_participient_status || "Not Booked";
                var oStatus = blockDatas.o_status;

                blockData.push(createBlockData(blockCount,bId,bLabelName,bLabelPrice,bParticipantId,bParticipantName,bParticipantStatus));

            });
            setBlockData(blockData);
            setShowLoading(false);



        })





    };



    const handleClose2  = (event)  => {



        var eventDatas = event.target.value.split("|");

        setShowLoading(true);
        db.collection("booking").doc(selectedEventId).collection("event_blocks").doc(eventDatas[0]).get().then(function (doc) {


                      if(doc.exists){

                          const blockDatas = doc.data();
                          var bId = blockDatas.b_id;
                          var bLabelName = blockDatas.b_lable_name;
                          var bLabelPrice = blockDatas.b_label_price;
                          var bParticipantId = blockDatas.b_participent_id;

                          if(bParticipantId===""){


                              swal("Event Not booked yet");
                              setShowLoading(false);

                          }else {

                              db.collection("booking").doc(selectedEventId).collection("event_blocks").doc(eventDatas[0]).update({

                                  "o_status":eventDatas[1],
                                  "b_participient_status":eventDatas[1]

                              }).then(()=>{

                                  setShowAlert(true);

                                  setTimeout(()=>{

                                      swal("saved");

                                      setShowAlert(false);
                                      setShowLoading(false);

                                  },2000)


                              })


                          }




                      }


        });





    };


    React.useState(()=>{

        db.collection("booking").where("o_id", "==",userId).where("b_e_status", "==","active").get().then((querySnapshot) => {


            if (querySnapshot.size <= 0){

                swal("No Active Events");

            }












        });



    })


    React.useEffect(()=>{

        db.collection("booking").where("o_id", "==",userId).where("b_e_status", "==","active").get().then((querySnapshot) => {


            var eCount = 0;

            activeBooking = [];




            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);

                eCount++;

                var ids = doc.id;

                const data = doc.data();
                var eId = data.b_e_id;
                var eName = data.b_e_name;
                var oName = data.o_name;
                var hallName = data.h_e_hall_name;

                var eStatus = data.b_e_status;
                var bookingData = "";



                activeBooking.push(createData(eCount,bookingData,eId,eName,oName,hallName,eStatus));

            });

            setTimeout(()=>{

                setActiveBooking(activeBooking);


            },1000)





        });




    });



    /*db.collection("booking").where("o_id", "==",userId).where("b_e_status", "==","active").get().then((querySnapshot) => {


        var eCount = 0;

        activeBooking = [];
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);

            eCount++;

            var ids = doc.id;

            const data = doc.data();
            var eId = data.b_e_id;
            var eName = data.b_e_name;
            var oName = data.o_name;
            var hallName = data.h_e_hall_name;
            var eStatus = data.b_e_status;
            var bookingData = "";



            activeBooking.push(createData(eCount,bookingData,eId,eName,oName,hallName,eStatus));

        });
        setActiveBooking(activeBooking);



    });*/







        return (<div>

            <div className={classes.root3}>
                {showAlert ? <Alert  severity="success" autoHideDuration={2000}>Successfully Updated</Alert>:null}

            </div>

            {showLoading===true ? <div className={classes.root4}>
                <CircularProgress  />
            </div>:null}


            <Button aria-controls="simple-menu" variant="outlined" aria-haspopup="true" onClick={handleClick}>
                 {selectedEventName}

            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
            >
                {activeBooking.map((row) => (

                    <MenuItem onClick={handleClose(row.eId+"|"+row.eName)}>{row.eName}</MenuItem>

                ))}


            </Menu>

            <Button   style={{marginLeft: '5px'}} aria-controls="simple-menu" variant="outlined" aria-haspopup="true" onClick={handleClick1}>
                {selectedStatus}
            </Button>
            <Menu
                id="simple-menu-2"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
            >

                <MenuItem onClick={handleClose1("new")}>

                    { selectedLanguage==='ar' ? "تحت المعالجة":selectedLanguage==='en' ? "New":null}
                </MenuItem>
                <MenuItem onClick={handleClose1("processing")}>

                    { selectedLanguage==='ar' ? "تحت المعالجة":selectedLanguage==='en' ? "Processing":null}
                </MenuItem>
                <MenuItem onClick={handleClose1("confirm")}>

                    { selectedLanguage==='ar' ? "تأكيد":selectedLanguage==='en' ? "Confirm":null}
                </MenuItem>
                <MenuItem onClick={handleClose1("active")}>

                    { selectedLanguage==='ar' ? "نشط":selectedLanguage==='en' ? "Active":null}
                </MenuItem>
                <MenuItem onClick={handleClose1("completed")}>

                    { selectedLanguage==='ar' ? "اكتمل":selectedLanguage==='en' ? "Completed":null}
                </MenuItem>

            </Menu>



            <Box mt={2}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">
                                { selectedLanguage==='ar' ? "رقم":selectedLanguage==='en' ? "SI.No":null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                { selectedLanguage==='ar' ? "اسم الطابع":selectedLanguage==='en' ? "Booth Name":null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                { selectedLanguage==='ar' ? "سعر الملصق":selectedLanguage==='en' ? "Booth Price":null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                { selectedLanguage==='ar' ? "اسم المشارك":selectedLanguage==='en' ? "Participant":null}
                            </StyledTableCell>
                            <StyledTableCell align="left">

                                { selectedLanguage==='ar' ? "الحالة":selectedLanguage==='en' ? "Status":null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                { selectedLanguage==='ar' ? "عمل":selectedLanguage==='en' ? "Action":null}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blockData.map((row) => (
                            <StyledTableRow key={row.bId}>
                                <StyledTableCell align="left">{row.bCount}</StyledTableCell>
                                <StyledTableCell align="left">{row.bLabelName}</StyledTableCell>
                                <StyledTableCell align="left">{row.bLabelPrice}</StyledTableCell>
                                <StyledTableCell align="left">{row.participantName}</StyledTableCell>
                                <StyledTableCell align="left">{row.oStatus}</StyledTableCell>
                                <StyledTableCell align="left">
                                    <FormControl className={classes.formControl}>
                                    <Select
                                        value={""}
                                        onChange={handleClose2}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">
                                            <em>{row.oStatus}</em>
                                        </MenuItem>
                                        <MenuItem value={row.bId+"|"+"processing"}>Processing</MenuItem>
                                        <MenuItem value={row.bId+"|"+"confirm"}>Confirm</MenuItem>
                                        <MenuItem value={row.bId+"|"+"active"}>Active</MenuItem>
                                        <MenuItem value={row.bId+"|"+"completed"}>Completed</MenuItem>
                                    </Select></FormControl>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>


        </div>);







}


export default Bookings;