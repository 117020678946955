import React from 'react';
//import { withStyles, makeStyles } from '@material-ui/core/styles';
import { makeStyles,withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {
    BrowserRouter as Router,
    Switch, Route, Link
} from "react-router-dom";




import firebase from '../services/firebase'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },


}))(TableRow);

function createData(oId,oCompanyName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType) {
  return { oId,oCompanyName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType };
}

const rows = [
];

const db = firebase.firestore();
db.collection("organiser").get().then((querySnapshot) => {


    querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);

        var ids = doc.id;
        console.log(ids);

        const data = doc.data();
        var oId = data.o_id;
        var oName = data.o_name;
        var cName = data.o_c_name;
        var oEmail = data.o_email;
        var oGender = data.o_gender;
        var phone = data.o_mobilenumber;
        var vatNumber = data.o_vat_number;
        var oCrNumber = data.o_cr_number;
        var oBussinessType = data.o_type;

        rows.push(createData(oId,cName,oName,oEmail,oGender,phone,vatNumber,oCrNumber,oBussinessType))

    });

});



/*const useStyles = makeStyles((theme: Theme) => {

    createStyles({

        table: {
            minWidth: 700,
        },
        link2:{

            textDecoration:'none',
            color:theme.palette.text.primary
        },

    });


});*/

const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        table: {
            minWidth: 700,
        },
        link:{

            textDecoration:'none',
            color:theme.palette.text.primary

        },


    }),
);



export default function Organizers() {
  const classes = useStyles();


  return (

    <div>
        {/*<Link to="/createOrganizer"  className={classes.link2}>
        <Button aria-controls="simple-menu" id="b-status-button" variant="contained" color="primary" aria-haspopup="true">
            Create
        </Button></Link>*/}
        <Box mt={2}  >
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
              <StyledTableCell align="left">CompanyName</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Phone</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Gender</StyledTableCell>
              <StyledTableCell align="left">Business Type</StyledTableCell>
              <StyledTableCell align="left">CR Number</StyledTableCell>
              <StyledTableCell align="left">VAT Number</StyledTableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.oId} component={Link} className={classes.link} to={'/organiserDetails/'+row.oId}>
                <StyledTableCell align="left">{row.oCompanyName}</StyledTableCell>
                <StyledTableCell align="left">{row.oName}</StyledTableCell>
                <StyledTableCell align="left">{row.phone}</StyledTableCell>
                <StyledTableCell align="left">{row.oEmail}</StyledTableCell>
                <StyledTableCell align="left">{row.oGender}</StyledTableCell>
                <StyledTableCell align="left">{row.oBussinessType}</StyledTableCell>
                <StyledTableCell align="left">{row.oCrNumber}</StyledTableCell>
                <StyledTableCell align="left">{row.vatNumber}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Box>
    </div>
  );
}
